import React from "react";
import { RoomFeatureItem } from "./RoomFeatureItem";
import styles from "./RoomFeatures.module.css";

export const RoomFeatureColumn = ({ features }) => {
  return (
    <div className={styles.featureColumn}>
      {features.map((feature, index) => (
        <RoomFeatureItem key={index} icon={feature.icon} text={feature.text} />
      ))}
    </div>
  );
};
