import React from "react";
import NavbarNew from "../Navbar/NavbarNew";
import styles from "./CheckoutJourney.module.css";
import ProgressBar from "./CheckOutJourney/PassengerDetails/components/ProgressBar";
import PassengerDetails from "./CheckOutJourney/PassengerDetails/PassengerDetails";
import FlightItineary from "./CheckOutJourney/FlightItineary/FlightItineary";
import FlightBookingReview from "./CheckOutJourney/FinalReview/FlightBookingReview";
import SeatMealSelection from "./CheckOutJourney/SeatBaggageSelection/SeatMealSelection";
import { useSelector } from "react-redux";

const CheckoutJourney = () => {
  const showProgress = useSelector(
    (state) => state.checkoutJourney.journeyProgress
  );
  return (
    <div>
      <NavbarNew />
      <div className={styles.checkoutProgressContainer}>
        <ProgressBar />
      </div>
      {showProgress === 0 && <FlightItineary />}
      {showProgress === 1 && <PassengerDetails />}
      {showProgress === 2 && <FlightBookingReview />}
      <SeatMealSelection />
    </div>
  );
};

export default CheckoutJourney;
