import * as React from "react";
import styles from "./TestimonialCard.module.css";

export default function TestimonialCard() {
  return (
    <div className={styles.testimonialCard}>
      <div className={styles.cardWrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.testimonialInfo}>
            <div className={styles.testimonialContent}>
              <div className={styles.authorSection}>
                <div className={styles.authorName}>Shilpa and Amit</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/226a1873392f28f814e230ce64f679076bf6fa43024dd130fe9bf1e1c756a467?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
                  className={styles.authorSignature}
                  alt="Shilpa and Amit's signature"
                />
              </div>
              <div className={styles.testimonialText}>
                The quick, brown fox jumps over a lazy dog. DJs flock by wheMTV
                ax quiz prog.{" "}
              </div>
              <div className={styles.location}>Singapore</div>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/27bcd93e488e390714e20fb6c846a47dffd73d5c68439d59231a84b21e6e78c6?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
              className={styles.testimonialImage}
              alt="Testimonial from Shilpa and Amit"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
