import React from 'react';
import styles from './FlightBookingReview.module.css';

const PassengerCard = ({ passengerNumber, name }) => {
  return (
    <>
    <div className={styles.passengerDetails}>
          <h3>Passenger Details</h3>
          <table>
            <thead>
              <tr>
                <th>Sno.</th>
                <th>Name, Age & Passport</th>
                <th>Seat Booking</th>
                <th>Meal & Baggage Preference</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>MR. XYZYYYZ</td>
                <td>NA</td>
                <td>NA</td>
              </tr>
              <tr>
                <td>2</td>
                <td>MR. XYZYYYZ</td>
                <td>NA</td>
                <td>NA</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={styles.contactDetails}>
          <h3>Contact Details</h3>
          <p>Email Address: XYZYYYZ@gmail.com</p>
          <p>Mobile No.: 123456789000</p>
        </div>
    </>
  );
};
export default PassengerCard