import { useState } from "react";

const useHotelSearch = () => {
  const [openCalendar, setOpenCalendar] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [counts, setCounts] = useState({ Adults: 2, Children: 0, Rooms: 1 });
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState({
    searchtext: null,
    checkin: null,
    checkout: null,
    acr: null,
  });

  const handleCheckInDateChange = (date) => setCheckInDate(date);
  const handleCheckOutDateChange = (date) => setCheckOutDate(date);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const updateCount = (key, delta) => {
    setCounts((prev) => ({
      ...prev,
      [key]: Math.max(
        key === "Rooms" || key === "Adults" ? 1 : 0,
        prev[key] + delta
      ),
    }));
  };

  const handleCalendarToggle = (label) => {
    setOpenCalendar((prev) => (prev === label ? null : label));
  };

  const searchHotelList = (navigate) => {
    const params = {
      searchtext: searchQuery.searchtext,
      checkin: checkInDate,
      checkout: checkOutDate,
      acr: `${counts.Adults}${counts.Children}${counts.Rooms}`,
    };
    setSearchQuery(params);
    const queryParams = new URLSearchParams(params).toString();
    navigate(`/hotel-listing?${queryParams}`);
  };

  return {
    openCalendar,
    isOpen,
    counts,
    checkInDate,
    checkOutDate,
    searchQuery,
    handleCheckInDateChange,
    handleCheckOutDateChange,
    toggleDropdown,
    updateCount,
    handleCalendarToggle,
    searchHotelList,
    setIsOpen,
    setSearchQuery,
  };
};

export default useHotelSearch;
