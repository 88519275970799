import React from "react";
import styles from "../styles/RatingButton.module.css";

export const RatingButton = ({ data, isSelected, onClick }) => {
  return (
    <div
      className={`${styles.ratingDiv} ${isSelected ? styles.selected : ""}`}
      onClick={() => onClick(data)}
    >
      {data}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M6.9974 10.0735L10.6024 12.2493L9.64573 8.14852L12.8307 5.38935L8.63656 5.03352L6.9974 1.16602L5.35823 5.03352L1.16406 5.38935L4.34906 8.14852L3.3924 12.2493L6.9974 10.0735Z"
          fill="#FFD700"
        />
      </svg>
    </div>
  );
};
