import React from "react";
import styles from "./HotelCard.module.css";

export function Rating({ score, likes }) {
  return (
    <div className={styles.ratingContainer}>
      <div className={styles.ratingScore}>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e25505676c6cdf9ff8034caa406b07d6acaf4a3a7cf9057f9c256b1ee221c8e?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491"
          className={styles.ratingIcon}
          alt=""
        />
        <div>{score}</div>
      </div>
      <div className={styles.ratingText}>{likes} people liked this</div>
    </div>
  );
}
