import React from "react";
import styles from "../PaymentForm.module.css";

export const FormField = ({ label, id, type = "text" }) => {
  return (
    <>
      <label htmlFor={id} className={styles.formLabel}>
        {label}
      </label>
      <input
        type={type}
        id={id}
        className={styles.formInput}
        aria-label={label}
      />
    </>
  );
};
