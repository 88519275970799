import React from "react";
import styles from "../PaymentForm.module.css";

export const SecurityMessage = ({ icon, text }) => {
  return (
    <div className={styles.securityMessage}>
      <img loading="lazy" src={icon} className={styles.securityIcon} alt="" />
      <div className={styles.securityText}>{text}</div>
    </div>
  );
};
