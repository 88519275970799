import React from "react";
import { FacilityCard } from "./FacilityCard";
import styles from "./Facilities.module.css";

const firstRowFacilities = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5598684c9dc8f3c437793424efecda7117d9fe0d89c9208fa431ed8daaf67a88?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Free Wi-Fi",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b89e8d0ecbc37ea96f86cfade19cdedd17b3b1fbd7961bbda3666ff615344b6b?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Massage",
    onDemand: true,
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/356fe5794f5213275897614399cfbac8d306db2035abc3723f0592dd0653e893?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Spa",
    onDemand: true,
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/fa3f5b44083b3a51739e72be766abb72aa628e71384f4af87110297785887962?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Airport transfer",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/fa3f5b44083b3a51739e72be766abb72aa628e71384f4af87110297785887962?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Airport transfer",
  },
];

const secondRowFacilities = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5598684c9dc8f3c437793424efecda7117d9fe0d89c9208fa431ed8daaf67a88?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Free Parking",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b89e8d0ecbc37ea96f86cfade19cdedd17b3b1fbd7961bbda3666ff615344b6b?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Restaurant",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a6154ae2d48867e455b41f4affcd1aa862779283e969aedb538fd2af5244dd4a?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Dry cleaning",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/46092ea901ab1d9383374757a6d51ea42b70c1c65e4ca54166306f1ec937da20?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Bar",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/fc2ee46614ac122b33ae6ed0fdab832c00ecdcfc138fbfced21c865730677a9e?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Fitness center",
  },
];

export function Facilities() {
  return (
    <div className={styles.container}>
      <div className={styles.facilitySection}>
        <h2 className={styles.heading}>Facilities</h2>
        <div className={styles.divider} />
        <div className={styles.content}>
          <div className={styles.facilityRow}>
            {firstRowFacilities.map((facility, index) => (
              <FacilityCard
                key={index}
                icon={facility.icon}
                text={facility.text}
                onDemand={facility.onDemand}
              />
            ))}
          </div>
          <div className={`${styles.facilityRow} ${styles.secondRow}`}>
            {secondRowFacilities.map((facility, index) => (
              <FacilityCard
                key={index}
                icon={facility.icon}
                text={facility.text}
                onDemand={facility.onDemand}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
