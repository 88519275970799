import React from 'react'
import styles from './FlightBookingReview.module.css'
import PassengerCard from './PassengerCard'
import { FlightTimeInfo } from '../FlightItineary/FlightDetails/FlightDetails/FlightTimeInfo'
import { AirlineInfo } from '../FlightItineary/FlightDetails/FlightDetails/AirlineInfo'
import FlightDetailsCard from './FlightDetailsCard'

const FlightReviewCompo = () => {
  return (
    <>
    <div className={styles.formContainer}>
    <div className={styles.formGrid}>
    <div className={styles.journeyDetails}>
          <div className={styles.routeInfo}>
            <h3>Delhi → Manali <span>Sat, Dec 4th 2024</span></h3>
          </div>
          <FlightDetailsCard/>
          <div className={styles.baggageInfo}>
            <p>(Adult) Check-in: 15kg, Cabin: 7kg</p>
          </div>
        </div>
        <PassengerCard/>
        <div className={styles.actions}>
          <button className={styles.backButton}>Back</button>
          <button className={styles.payButton}>Proceed To Pay</button>
        </div>
        </div>
        </div>
    </>
  )
}

export default FlightReviewCompo