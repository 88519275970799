import React from "react";
import styles from "./Filters.module.css";

export const FilterCheckbox = ({ label }) => {
  return (
    <div className={styles.checkboxContainer}>
      <input className={styles.checkbox} type="checkbox" tabIndex={0} />
      <div className={styles.checkboxLabel}>{label}</div>
    </div>
  );
};
