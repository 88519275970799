import React from "react";
import { AirlineInfo } from "../FlightItineary/FlightDetails/FlightDetails/AirlineInfo";
import styles from "./FlightDetailsCard.module.css";
import { FlightTimeInfo } from "../FlightItineary/FlightDetails/FlightDetails/FlightTimeInfo";

const FlightDetailsCard = () => {
  const flightDetails = {
    Airline: "Air India",
    FlightNumber: "AI-205/77W",
    TotalStops: "Non-Stop",
    Class: "Economy",
    Route: {
      Departure: {
        City: "Delhi",
        AirportCode: "DEL",
        Date: "4 Dec, Sat",
        Time: "06:45",
        Airport: "Indira Gandhi Intl Terminal 3",
      },
      Arrival: {
        City: "Manali",
        AirportCode: "KUU",
        Date: "6 Dec, Mon",
        Time: "08:10",
        Airport: "Bhuntar Airport Terminal 1",
      },
    },
    Duration: "1 hr 20 min",
    PriceOptions: [
      { price: "27,078", fareType: "Ndc Economy Saver" },
      { price: "27,078", fareType: "Ndc Economy Saver" },
      { price: "27,078", fareType: "Ndc Economy Saver" },
    ],
  };
  return (
    <div className={styles.flightCard}>
      <div className={styles.container}>
        <div className={styles.flightHeader}>
          <AirlineInfo
            logoSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/227318a0a5b4920c2c1c2dd48527b163ef0b0b13c7448023e0205a95089301b4?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
            airlineName={flightDetails?.Airline}
            flightNumber={flightDetails?.FlightNumber}
          />
          <div className={styles.contentWrapper}>
            <FlightTimeInfo
              date={flightDetails?.Route?.Departure?.Date}
              time={flightDetails?.Route?.Departure?.Time}
              location={flightDetails?.Route?.Departure?.City}
              airport={flightDetails?.Route?.Departure?.Airport}
            />
            <div className={styles.durationColumn}>
              <div className={styles.durationWrapper}>
                <div className={styles.durationLine}>
                  <div className={styles.line} />
                  <div className={styles.duration}>
                    {flightDetails?.Duration}
                  </div>
                </div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/d35131fd8568cb6d61f61ab98edd00f096de686749743bce0ff36a8b5d1845b0?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
                  className={styles.durationIcon}
                  alt=""
                />
              </div>
            </div>
            <div className={styles.arrivalColumn}>
              <div className={styles.arrivalInfo}>
                <div className={styles.arrivalDateTime}>
                  <div className={styles.arrivalDate}>
                    {flightDetails?.Route?.Arrival?.Date}
                  </div>
                  <div>{flightDetails?.Route?.Arrival?.Time}</div>
                </div>
                <div className={styles.airport}>
                  {flightDetails?.Route?.Arrival?.AirportCode}
                </div>
                <div>{flightDetails?.Route?.Arrival?.Airport}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.divider} />
    </div>
  );
};

export default FlightDetailsCard;
