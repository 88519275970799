import React from "react";
import styles from "./PhotoGallery.module.css";
import { GalleryImage } from "./GalleryImage";

const images = [
  "https://cdn.builder.io/api/v1/image/assets/TEMP/5ee468a2f2bd1e4ca98a7bf5ce59dfe9d650d3142131bbcaee5a560de178a367?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/fa2666f433930a02d0564cbe47cb0e85fc1ce99ba22f01d537423c7654d725e5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/5622d9f1bcbc862650347575c5434a2ef8d0de11d8c311f1a8efdeee652e7675?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/b05fe4ef815ccb3ff9c647136f3c38b57e9a21c051c4275347329933b16ed620?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
];

export const ReviewGallery = () => {
  return (
    <div className={styles.container}>
      <div className={styles.galleryWrapper}>
        <h2 className={styles.galleryTitle}>Photos by Guest</h2>
        <div className={styles.divider} />
        <div className={styles.galleryGrid}>
          <div className={styles.gridContainer}>
            {images.map((src, index) => (
              <GalleryImage key={src} src={src} index={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
