import React from "react";
import styles from "./ImageGallery.module.css";

export const HotelGalleryImage = ({ src, className, alt }) => {
  return (
    <img
      loading="lazy"
      src={src}
      className={`${styles.galleryImage} ${className}`}
      alt={alt}
    />
  );
};
