import React from "react";
import styles from "./RoomCard.module.css";

export default function RoomCard({
  title,
  price,
  isSelected = false,
  onSelect,
}) {
  return (
    <div
      className={`${styles.roomCard} ${isSelected ? styles.selectedCard : ""}`}
      onClick={onSelect}
    >
      <div className={isSelected ? styles.selectedTitle : styles.roomTitle}>
        {title}
        {isSelected && (
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c5cf9b4-2a2e-4b78-a69a-62369e90b5c2?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
            className={styles.statusIcon}
            alt="Selected"
          />
        )}
      </div>
      <div className={isSelected ? styles.selectedPrice : styles.priceText}>
        Starting @ Rs.{price}
      </div>
    </div>
  );
}
