import React from "react";
import styles from "./HotelCard.module.css";
import { Tag } from "./Tag";
import { useNavigate } from "react-router-dom";

export const HotelCard = ({ id }) => {
  const tags = ["Great For Families", "Location 4.5/5.0"];
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.cardWrapper}>
        <div className={styles.imageColumn}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a7e90e71a669db02b0a68d18ec673434d97026377d8a85a44557d151a8fc6c41?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
            className={styles.hotelImage}
            alt="Sun Park Resort exterior view"
          />
        </div>
        <div className={styles.contentColumn}>
          <div className={styles.contentWrapper}>
            <h2 className={styles.hotelName}>Sun Park Resort</h2>
            <div className={styles.tagContainer}>
              {tags.map((tag, index) => (
                <Tag key={index} text={tag} />
              ))}
            </div>
            <div className={styles.priceSection}>
              <div className={styles.priceWrapper}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/0769db32afb320e178a6504f8c4524824ae2aec81839c96579204c0cb62bc85d?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
                  className={styles.currencyIcon}
                  alt=""
                />
                <div className={styles.priceText}>
                  2,978
                  <span className={styles.priceSubtext}>a Night</span>
                </div>
              </div>
              <button
                className={styles.bookButton}
                onClick={() => navigate(`/hotel-listing/${id}`)}
                tabIndex={0}
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
