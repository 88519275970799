import React from 'react';
import styles from './FlightFilter.module.css';
import { FilterSection } from './FilterSection';

const stopOptions = ['Direct', '1 Stop', '2+ Stops'];
const airlineOptions = ['Air India', 'Air India', 'Air India', 'Air India'];
const travelClassOptions = ['Economy', 'Premium Economy', 'Business Class', 'First Class'];
const refundOptions = ['Refundable', 'Non Refundable'];

export const FlightFilter = () => {
  return (
    <div className={styles.container}>
      <div className={styles.filterCard}>
        <FilterSection title="Stops" options={stopOptions} />
        <div className={styles.divider} />
        
        <div className={styles.filterSection}>
          <div className={styles.sectionTitle}>Journey Duration</div>
          <div className={styles.durationSlider} role="slider" tabIndex={0} aria-label="Journey Duration" />
        </div>
        <div className={styles.divider} />
        
        <FilterSection title="Airline" options={airlineOptions} />
        <div className={styles.divider} />
        
        <div className={styles.priceSection}>
          <div className={styles.sectionTitle}>Price</div>
          <div className={styles.priceIndicator}>
            <div>5,021</div>
            <div className={styles.priceCircle} />
          </div>
        </div>
        <div className={styles.divider} />
        
        <FilterSection title="Travel Class" options={travelClassOptions} />
        <div className={styles.divider} />
        
        <FilterSection title="" options={refundOptions} />
        <div className={styles.divider} />
        
        <div className={styles.specialPreferences}>
          <div className={styles.sectionTitle}>Special Preferences</div>
          <div>Meal Options</div>
          <div>Seat Type</div>
        </div>
      </div>
    </div>
  );
};