import React from "react";
import { RoomFeatureColumn } from "./RoomFeatureColumn";
import styles from "./RoomFeatures.module.css";

const leftColumnFeatures = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ea714790-cabb-4654-af83-c0f7418ec163?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "1 double bed",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c9403cf66c83e221f65cd6f4eb0aa992186a6c0d73957aba8105a49788c94a80?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Separate shower/bathtub",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4ddf6218acad7497a4a788f06bfadc00b1396d0214a945b17722a14b20971e4b?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Room size:180ft",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e63313a9147eabdbfbc6acb96072c5815c2083f91f5c510faaa66544ad548636?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Free Wi-Fi",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6871f6b5b73a26f39effd4b8e454d579a415f8453cee6d4a91418aea7729a08a?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Balcony/terrace",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/56cce50aeb85e4f1935b513de22f14b942d164b99fc9893dc9ceb76b70fc8775?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Long windows",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7356e9080b900a629163432bd7ba0953d90d232ecb4aa2cb7ab66e1c5d8caf50?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Smoking allowed",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/707dd9c1fa9c1ffa116974743e8f0f35dd1cc0bb9ca41222300e32da86b761eb?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Mountain view",
  },
];

export const RoomFeatures = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <RoomFeatureColumn features={leftColumnFeatures} />
      </div>
    </div>
  );
};
