import React from "react";
import styles from "./PassengerDetails.module.css";
import { PassengerForm } from "./components/PassengerForm";
import { FlightSummary } from "./components/FlightSummary";

const PassengerDetails = () => {
  return (
    <div className={styles.container}>
      <main className={styles.mainContent}>
        <div className={styles.contentGrid}>
          <div className={styles.leftColumn}>
            <h1>Passenger Details</h1>
            <PassengerForm />
          </div>

          <div className={styles.rightColumn}>
            <FlightSummary flightDetails={true}/>
          </div>
        </div>
      </main>
    </div>
  );
};
export default PassengerDetails;
