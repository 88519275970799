import React from "react";
import TestimonialCard from "./TestimonialCard";

const RoomTestimonials = () => {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        justifyContent: "center",
        background: "#D5ABAB",
        padding: "2rem",
        margin: "2rem 0rem",
      }}
    >
      {Array.from({ length: 3 }, (_, id) => (
        <TestimonialCard />
      ))}
    </div>
  );
};

export default RoomTestimonials;
