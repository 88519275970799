import React from "react";
import styles from "./HotelCard.module.css";
import { RatingIcon } from "./RatingIcon";

const ratingIcons = [
  "https://cdn.builder.io/api/v1/image/assets/TEMP/47c9b61b491c9ec05a5f6e8698f798bc3ed69ee65931b71458efc56ad795f8c9?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
  "https://cdn.builder.io/api/v1/image/assets/TEMP/cfb9bef0e99ef754c5aea9f49f3a0d7a8e0b876de47652e2cad2afcdeb95b3a0?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
];

export const HotelName = () => {
  return (
    <div className={styles.hotelCard}>
      <div className={styles.cardContainer}>
        <div className={styles.headerSection}>
          <h1 className={styles.hotelName}>Sun Park Resort</h1>
          <div className={styles.ratingContainer}>
            {ratingIcons.map((icon, index) => (
              <RatingIcon key={index} src={icon} />
            ))}
          </div>
        </div>
        <div className={styles.divider} />
        <p className={styles.description}>
          Stay at this charming hotel in Manali which offers a restaurant, lawn,
          spacious rooms, Wi-Fi & mesmerising hill views
        </p>
      </div>
    </div>
  );
};
