import { HotelFilterMobile } from "../SearchBar/HotelFilterMobile";
import { X } from "lucide-react";

import React, { useState } from "react";
import styles from "./SearchFilters.module.css";
import { FilterButton } from "./FilterButton";
import { DateGuestInfo } from "./DateGuestInfo";
import { HotelListingCardMobile } from "../hotelCard/HotelListingCardMobile";
import { Drawer } from "@mui/material";
import DrawerContent from "../DrawerContent/DrawerContent";

export const HotelTopBarMobile = () => {
  const [showFilter, setShowFilter] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");

  const filters = [
    { label: "Price" },
    { label: "Location" },
    { label: "Star Rating" },
    { label: "Free Cancellation" },
  ];

  const toggleDrawer = (isOpen, filterLabel = "") => {
    setDrawerOpen(isOpen);
    setActiveFilter(filterLabel);
  };

  const getDrawerContent = () => {
    switch (activeFilter) {
      case "Price":
        return <DrawerContent />;
      case "Location":
        return <p>Select location options</p>;
      case "Star Rating":
        return <p>Pick star ratings</p>;
      case "Free Cancellation":
        return <p>View hotels with free cancellation</p>;
      default:
        return null;
    }
  };

  return (
    <>
      {showFilter ? (
        <HotelFilterMobile setShowFilter={setShowFilter} />
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.location}>Bali, Indonesia</div>
            <DateGuestInfo dates="24 OCT-26 OCT" guestCount={3} />
            <div className={styles.filterContainer}>
              <div onClick={() => setShowFilter(!showFilter)}>
                <FilterButton
                  icon="https://cdn.builder.io/api/v1/image/assets/TEMP/fa3b33a5fcdb98d59c26c03445cf45e0adba2f5821254a85cf2442772285663d?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491"
                  label="Filters"
                />
              </div>
              {filters.map((filter, index) => (
                <div
                  key={index}
                  onClick={() => toggleDrawer(true, filter.label)}
                >
                  <FilterButton label={filter.label} />
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-3 justify-center">
            {Array.from({ length: 9 }, (_, id) => (
              <HotelListingCardMobile key={id} />
            ))}
          </div>

          <Drawer
            anchor="bottom"
            open={drawerOpen}
            onClose={() => toggleDrawer(false)}
          >
            <div className={styles.drawerContent}>
              <h2>
                <span />
                {activeFilter} Filter
                <X color="red" />
              </h2>
              {getDrawerContent()}
              <div className={styles.result}>Show Result</div>
            </div>
          </Drawer>
        </>
      )}
    </>
  );
};
