import React from "react";
import styles from "./FacilityCard.module.css";

export function FacilityCard({ icon, text, onDemand, size }) {
  return (
    <div className={styles.facilityItem}>
      <img
        loading="lazy"
        src={icon}
        alt={`${text} facility icon`}
        className={styles.facilityIcon}
      />
      <div className={styles.facilityText}>
        {text}
        {onDemand && (
          <span>
            <span className={styles.lightWeight}>(</span>
            <span className={`${styles.lightWeight} ${styles.smallText}`}>
              on-demand
            </span>
            <span className={styles.lightWeight}>)</span>
          </span>
        )}
      </div>
    </div>
  );
}
