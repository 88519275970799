import React from "react";
import { InputField } from "./components/InputField";
import { Checkbox } from "./components/Checkbox";
import styles from "./styles/CheckInForm.module.css";

export const CheckInForm = () => {
  return (
    <form className={styles.container}>
      <div className={styles.formWrapper}>
        <h1 className={styles.title}>Who's ready to check in?</h1>
        <p className={styles.roomInfo}>
          <span className={styles.roomNumber}>Room 1</span>: 2 Adults
        </p>

        <div className={styles.nameFields}>
          <InputField label="First Name" id="firstName" />
          <InputField label="Surname" id="surname" />
        </div>

        <InputField label="Email Address" type="email" id="email" />

        <Checkbox label="Please send me Expedia emails with travel deals, special offers and other information." />

        <div className={styles.phoneSection}>
          <div className={styles.countryCode}>
            <label htmlFor="countryCode" className={styles.phoneLabel}>
              Mobile Phone No.
            </label>
            <div className={styles.codeSelector}>
              <span>IND +91</span>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e9a21d6384d5d7a0c5880be314e616ed2fd558f2995d491eb547ec76f4a700b0?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
                alt=""
                className={styles.dropdownIcon}
              />
            </div>
          </div>
          <InputField label="Phone Number" type="tel" id="phoneNumber" />
        </div>

        <Checkbox label="Receive text alerts about this trip (free of charge)" />
      </div>
    </form>
  );
};
