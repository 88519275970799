import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./HotelCalender.css";
const HotelCalender = ({ label, isOpen, onToggle, onDateChange }) => {
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState("");
  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    console.log(selectedDate);
    onDateChange(selectedDate.toLocaleDateString());
  };

  const handleDone = () => {
    setSelectedDate(date.toLocaleDateString());
    onToggle();
  };

  return (
    <div style={{ fontFamily: "Arial" }}>
      <div style={{ color: "white", position: "relative", top: "0.7rem" }}>
        <p style={{ position: "relative", top: "-0.7rem" }}>{label}</p>
        <div onClick={onToggle} className="checkin">
          {selectedDate || "Select Date"}
        </div>{" "}
      </div>

      {isOpen && (
        <div className="custom-calendar-wrapper">
          <Calendar
            onChange={handleDateChange}
            value={date}
            minDate={new Date()}
          />
          <div className="calendar-actions">
            <button
              onClick={onToggle}
              className="calendar-button cancel-button"
            >
              Cancel
            </button>
            <button
              onClick={handleDone}
              className="calendar-button done-button"
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelCalender;
