import { PlaneTakeoff } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";

const FlightSearchDropdown = ({onFlightSelection}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const flightSearch = useRef(null);

  const recentSearches = [
    { city: "Ahmedabad, India", airportCode: "AMD", airportName: "Sardar Vallabhbhai Patel International Airport" },
    { city: "Mumbai, India", airportCode: "BOM", airportName: "Chhatrapati Shivaji International Airport" },
  ];

  const popularCities = [
    { city: "Mumbai, India", airportCode: "BOM", airportName: "Chhatrapati Shivaji International Airport" },
    { city: "New Delhi, India", airportCode: "DEL", airportName: "Indira Gandhi International Airport" },
  ];
  const suggestionFlights = []

  const filteredRecent = recentSearches.filter((flight) =>
    flight.city.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredPopular = popularCities.filter((flight) =>
    flight.city.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const focuseInput = () => {
    if (!flightSearch.current) return;
    else flightSearch.current.focus();
  };

  useEffect(() =>{
    focuseInput()
  },[])
  return (
    <div className="flight-search-dropdown dropdown">
      <input
        type="text"
        className="search-input"
        placeholder="To"
        value={searchQuery}
        ref={flightSearch}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
        {
          suggestionFlights.length <= 0 ?
          <div className="dropdown-items">
          {filteredRecent.length > 0 && (
            <div className="dropdown-section">
              <h4 className="section-title">RECENT SEARCHES</h4>
              <ul className="dropdown-list">
                {filteredRecent.map((flight, index) => (
                  <li key={index} className="dropdown-item" onClick={() => onFlightSelection(flight)}>
                    <PlaneTakeoff className="icon-flight" />
                    <div className="flight-info">
                      <p className="city">{flight.city}</p>
                      <p className="airport-name">{flight.airportName}</p>
                    </div>
                    <div className="airport-code">{flight.airportCode}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {filteredPopular.length > 0 && (
            <div className="dropdown-section">
              <h4 className="section-title">POPULAR CITIES</h4>
              <ul className="dropdown-list">
                {filteredPopular.map((flight, index) => (
                  <li key={index} className="dropdown-item" onClick={() => onFlightSelection(flight)}>
                    <PlaneTakeoff className="icon-flight" />
                    <div className="flight-info">
                      <p className="city">{flight.city}</p>
                      <p className="airport-name">{flight.airportName}</p>
                    </div>
                    <div className="airport-code">{flight.airportCode}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div> :
        <div className="dropdown-items">
        {filteredRecent.length > 0 && (
          <div className="dropdown-section">
            <h4 className="section-title">SUGGESTIONS</h4>
            <ul className="dropdown-list">
              {filteredRecent.map((flight, index) => (
                <li key={index} className="dropdown-item" onClick={() => onFlightSelection(flight)}>
                  <PlaneTakeoff className="icon-flight" />
                  <div className="flight-info">
                    <p className="city">{flight.city}</p>
                    <p className="airport-name">{flight.airportName}</p>
                  </div>
                  <div className="airport-code">{flight.airportCode}</div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
        }
    </div>
  );
};

export default FlightSearchDropdown;
