import React from "react";
import { Rating } from "./Rating";
import { PriceDisplay } from "./PriceDisplay";
import styles from "./HotelCard.module.css";

export function HotelListingCardMobile() {
  return (
    <div className={styles.hotelCard}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d4af3639ca7d545eda848530b2ea4c0d5e598d7cf85aa738b9c30ab57d83996a?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491"
        className={styles.coverImage}
        alt="Paradise Resort exterior view"
      />
      <div className={styles.contentWrapper}>
        <Rating score={3.9} likes="85/100" />
        <div className={styles.infoContainer}>
          <div className={styles.hotelInfo}>
            <div className={styles.hotelName}>Paradise Resort</div>
            <div className={styles.cancellationText}>Free Cancellation</div>
            <div className={styles.discountText}>20% off</div>
          </div>
          <PriceDisplay mainPrice={2102} taxesAndFees={280} />
        </div>
      </div>
    </div>
  );
}
