import React from "react";
import { HotelTopBarMobile } from "./hotellisting-topbar/HotelTopBarMobile";

const HotelListingMobile = () => {
  return (
    <div className="px-9">
      <HotelTopBarMobile />
    </div>
  );
};

export default HotelListingMobile;
