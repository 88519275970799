import React from "react";
import { HighlightCard } from "./HighlightCard";
import styles from "./Highlights.module.css";

const highlightData = [
  {
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/890110fd168dcbcc09b6038edc01a6ab0b1f906b22dbcf209e96e4bcfcd4d712?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "890 meters to public transportation",
    alt: "Public transportation icon",
  },
  {
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/35f71881-96ca-4015-baf4-bbadabfa6633?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Great for activities",
    alt: "Activities icon",
  },
  {
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/62771975c2b3d685930a42175428d8317203c927170c7d573d8afd5d07048f3e?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Airport transfer",
    alt: "Airport transfer icon",
  },
  {
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/e387b42cce979e205c9ac40c150fe1c4d76e7157939b3fed51de0432de887d03?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Inside city center",
    alt: "City center icon",
  },
];

export function Highlights() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Highlights</h2>
        <div className={styles.divider} />
        <div className={styles.content}>
          <div className={styles.grid}>
            {highlightData.map((highlight, index) => (
              <div key={index} className={styles.column}>
                <HighlightCard {...highlight} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
