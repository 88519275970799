import React from "react";
import styles from "./PhotoGallery.module.css";

export const GalleryImage = ({ src, index }) => {
  return (
    <div className={styles.gridColumn}>
      <img
        loading="lazy"
        src={src}
        className={styles.galleryImage}
        alt={`Guest photo ${index + 1}`}
      />
    </div>
  );
};
