import React from "react";
import styles from "./RoomServices.module.css";

const services = [
  "Free breakfast",
  "Non refundable",
  "Free self parking",
  "Free Wi-Fi",
  "Mineral water - Additional charge",
  "Free breakfast",
  "Free breakfast",
];

export function RoomServices() {
  return (
    <div className={styles.servicesCard}>
      <h2 className={styles.title}>Room Services</h2>
      <div className={styles.divider} />
      <div className={styles.servicesList}>
        {services.map((service, index) => (
          <div key={`service-${index}`} className={styles.serviceItem}>
            <div className={styles.serviceBullet} />
            <span>{service}</span>
          </div>
        ))}
        <button className={styles.moreButton}>+ More Services</button>
      </div>
    </div>
  );
}
