import React from 'react'
import './FlightNav.module.css'
import BookFlightCard from '../BookFlightCard'

const FlightNav = () => {
  return (
    <div><BookFlightCard /></div>
  )
}

export default FlightNav