import React, { useState } from "react";
import { PriceOption } from "./PriceOption";
import styles from "./FlightCard.module.css";
import FlightTabs from "../FlightDetails/FlightTabs";

export const FlightCard = ({ flight }) => {
  const flightDatails = flight.FlightDetails;
  const [openFlightDetails, setOpenFlightDetails] = useState(false);
  const flightDetailopenCard = () => setOpenFlightDetails(!openFlightDetails);
  return (
    <>
      <div className={styles.flightCard}>
        <div className={styles.cardContent}>
          <div className={styles.airlineInfo}>
            <div className={styles.airlineLogo}>
              <div className={styles.logoWrapper}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/227318a0a5b4920c2c1c2dd48527b163ef0b0b13c7448023e0205a95089301b4?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
                  className={styles.logo}
                  alt="Air India logo"
                />
                <div>{flightDatails?.Airline}</div>
              </div>
            </div>
          </div>

          <div className={styles.flightTiming}>
            <div className={styles.timeInfo}>
              <div className={styles.locationTime}>
                <div>{flightDatails?.Route?.Departure?.AirportCode}</div>
                <div className={styles.time}>
                  {flightDatails?.Route?.Departure?.Time}
                </div>
                <div className={styles.date}>
                  {flightDatails?.Route?.Departure?.Date}
                </div>
              </div>
              <div className={styles.stopInfo}>
                <div>{flightDatails.TotalStops}</div>
                <div className={styles.line} />
                <div className={styles.durationIndicator}>
                  <div className={styles.duration}>
                    {flightDatails?.Duration}
                  </div>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d35131fd8568cb6d61f61ab98edd00f096de686749743bce0ff36a8b5d1845b0?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
                    className={styles.planeIcon}
                    alt="Plane icon"
                  />
                </div>
              </div>
              <div className={styles.locationTimeArriVal}>
                <div>{flightDatails?.Route?.Arrival?.AirportCode}</div>
                <div className={styles.time}>
                  {flightDatails?.Route?.Arrival?.Time}
                </div>
                <div className={styles.date}>
                  {flightDatails?.Route?.Arrival?.Date}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.priceSection}>
            <div className={styles.priceOptions}>
              {flightDatails?.PriceOptions?.map((option, index) => (
                <PriceOption
                  key={index}
                  price={option.price}
                  fareType={option.fareType}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <button
            className={styles.detailsButton}
            onClick={flightDetailopenCard}
          >
            View Details
          </button>
          <button className={styles.buyNowButton}>Buy Now</button>
        </div>
        {openFlightDetails && <FlightTabs flight={flight} />}
      </div>
    </>
  );
};
