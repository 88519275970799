import React from 'react';
import styles from './FlightCard.module.css';

export const FlightTimeInfo = ({ date, time, location, airport, terminal }) => {
  return (
    <div className={styles.departureInfo}>
      <div className={styles.dateTime}>
        <div className={styles.dateText}>{date}</div>
        <div>{time}</div>
      </div>
      <div className={styles.airport}>{location}</div>
      <div>{airport}</div>
      {/* <div className={styles.terminal}>{terminal}</div> */}
    </div>
  );
};