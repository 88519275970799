import React from "react";
import { Facilities } from "./components/facilities/Facilities";
import { HotelName } from "./components/hotelName/HotelName";
import { RoomBookingCard } from "./components/roomBooking/RoomBookingCard";
import { Highlights } from "./components/highlights/Highlights";
import { ReviewCard } from "./components/reviews/ReviewCard";
import "./HotelDetailSection.css";
const HotelDetailSections = () => {
  return (
    <div className="HotelDetailSectionsWrapper">
      <div>
        <HotelName />
        <Facilities />
        <Highlights />
      </div>
      <div>
        <RoomBookingCard />
        <ReviewCard />
      </div>
    </div>
  );
};

export default HotelDetailSections;
