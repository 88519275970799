import React from "react";
import styles from "./HighlightCard.module.css";

export function HighlightCard({ iconSrc, text, iconWidth, alt }) {
  return (
    <div className={styles.card}>
      <img
        loading="lazy"
        src={iconSrc}
        className={styles.icon}
        style={{ width: iconWidth }}
        alt={alt}
      />
      <div className={styles.text}>{text}</div>
    </div>
  );
}
