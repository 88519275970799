import React from "react";
import styles from "./RoomFeatures.module.css";

export const RoomFeatureItem = ({ icon, text }) => {
  return (
    <div className={styles.featureItem}>
      <img
        loading="lazy"
        src={icon}
        alt={text}
        className={styles.featureIcon}
      />
      <div className={styles.featureText}>{text}</div>
    </div>
  );
};
