import React, { Fragment } from "react";
import { FlightFilter } from "./FlightFilters/FlightFilter";
import styles from "./FlightListing.module.css";
import { FlightCard } from "./FligtListings/FlightCard";
import NavbarNew from "../Navbar/NavbarNew";
import Footer from "../Footer/Footer";
import FlightNav from "./FlightsNavbar/FlightNav";

const FlightsListingPage = () => {
  const flights = [
    {
      FlightDetails: {
        Airline: "Air India",
        FlightNumber: "AI-205/77W",
        TotalStops:"Non-Stop",
        Class: "Economy",
        Route: {
          Departure: {
            City: "Delhi",
            AirportCode: 'DEL',
            Date: "4 Dec, Sat",
            Time: "06:45",
            Airport: "Indira Gandhi Intl Terminal 3"
          },
          Arrival: {
            City: "Manali",
            AirportCode: 'KUU',
            Date: "6 Dec, Mon",
            Time: "08:10",
            Airport: "Bhuntar Airport Terminal 1"
          }
        },
        Duration: "1 hr 20 min",
        PriceOptions:[
          { price: '27,078', fareType: 'Ndc Economy Saver' },
          { price: '27,078', fareType: 'Ndc Economy Saver' },
          { price: '27,078', fareType: 'Ndc Economy Saver' }
        ]
      },
      FareDetails: {
        BaseFare: 20000,
        TaxesAndFees: 5000,
        AirlineSurcharges: 2000,
        OptionalAddOns: {
          SeatSelection: "NA",
          MealPreferences: "NA",
          ExtraBaggage: "NA"
        },
        Total: 27078
      },
      BaggageInformation: {
        CarryOnBaggage: {
          WeightLimit: "Max 7 Kg",
          ItemsAllowed: "Check your baggage allowances and fees to ensure you travel light.",
          ProhibitedItems: "Check your baggage allowances and fees to ensure you travel light."
        },
        CheckedBaggage: {
          Allowance: "Max 25 Kg",
          ExcessBaggageFees: "Max 25 Kg",
          ProhibitedItems: "Max 25 Kg"
        }
      },
      CancellationAndRefundPolicy: {
        FreeCancellation: "Cancel within 24 hours of booking for a full refund.",
        After24Hours: "Cancellations made after 24 hours or within 7 days of departure will incur a fee of XX.",
        RefundProcess: "Refunds are processed within 10-14 business days.",
        CancellationFees: "Fee of XX for cancellations within 7 days of departure."
      }
    },
    {
      FlightDetails: {
        Airline: "Air India",
        FlightNumber: "AI-205/77W",
        TotalStops:"Non-Stop",
        Class: "Economy",
        Route: {
          Departure: {
            City: "Delhi",
            AirportCode: 'DEL',
            Date: "4 Dec, Sat",
            Time: "06:45",
            Airport: "Indira Gandhi Intl Terminal 3"
          },
          Arrival: {
            City: "Manali",
            AirportCode: 'KUU',
            Date: "6 Dec, Mon",
            Time: "08:10",
            Airport: "Bhuntar Airport Terminal 1"
          }
        },
        Duration: "1 hr 20 min",
        PriceOptions:[
          { price: '27,078', fareType: 'Ndc Economy Saver' },
          { price: '27,078', fareType: 'Ndc Economy Saver' },
          { price: '27,078', fareType: 'Ndc Economy Saver' }
        ]
      },
      FareDetails: {
        BaseFare: 20000,
        TaxesAndFees: 5000,
        AirlineSurcharges: 2000,
        OptionalAddOns: {
          SeatSelection: "NA",
          MealPreferences: "NA",
          ExtraBaggage: "NA"
        },
        Total: 27078
      },
      BaggageInformation: {
        CarryOnBaggage: {
          WeightLimit: "Max 7 Kg",
          ItemsAllowed: "Check your baggage allowances and fees to ensure you travel light.",
          ProhibitedItems: "Check your baggage allowances and fees to ensure you travel light."
        },
        CheckedBaggage: {
          Allowance: "Max 25 Kg",
          ExcessBaggageFees: "Max 25 Kg",
          ProhibitedItems: "Max 25 Kg"
        }
      },
      CancellationAndRefundPolicy: {
        FreeCancellation: "Cancel within 24 hours of booking for a full refund.",
        After24Hours: "Cancellations made after 24 hours or within 7 days of departure will incur a fee of XX.",
        RefundProcess: "Refunds are processed within 10-14 business days.",
        CancellationFees: "Fee of XX for cancellations within 7 days of departure."
      }
    },
    {
      FlightDetails: {
        Airline: "Air India",
        FlightNumber: "AI-205/77W",
        TotalStops:"Non-Stop",
        Class: "Economy",
        Route: {
          Departure: {
            City: "Delhi",
            AirportCode: 'DEL',
            Date: "4 Dec, Sat",
            Time: "06:45",
            Airport: "Indira Gandhi Intl Terminal 3"
          },
          Arrival: {
            City: "Manali",
            AirportCode: 'KUU',
            Date: "6 Dec, Mon",
            Time: "08:10",
            Airport: "Bhuntar Airport Terminal 1"
          }
        },
        Duration: "1 hr 20 min",
        PriceOptions:[
          { price: '27,078', fareType: 'Ndc Economy Saver' },
          { price: '27,078', fareType: 'Ndc Economy Saver' },
          { price: '27,078', fareType: 'Ndc Economy Saver' }
        ]
      },
      FareDetails: {
        BaseFare: 20000,
        TaxesAndFees: 5000,
        AirlineSurcharges: 2000,
        OptionalAddOns: {
          SeatSelection: "NA",
          MealPreferences: "NA",
          ExtraBaggage: "NA"
        },
        Total: 27078
      },
      BaggageInformation: {
        CarryOnBaggage: {
          WeightLimit: "Max 7 Kg",
          ItemsAllowed: "Check your baggage allowances and fees to ensure you travel light.",
          ProhibitedItems: "Check your baggage allowances and fees to ensure you travel light."
        },
        CheckedBaggage: {
          Allowance: "Max 25 Kg",
          ExcessBaggageFees: "Max 25 Kg",
          ProhibitedItems: "Max 25 Kg"
        }
      },
      CancellationAndRefundPolicy: {
        FreeCancellation: "Cancel within 24 hours of booking for a full refund.",
        After24Hours: "Cancellations made after 24 hours or within 7 days of departure will incur a fee of XX.",
        RefundProcess: "Refunds are processed within 10-14 business days.",
        CancellationFees: "Fee of XX for cancellations within 7 days of departure."
      }
    },
    
  ];
  return (
    <div>
      <NavbarNew />
      <FlightNav />
      <div className={styles.flight_container}>
        <div className={styles.flight_inner_container}>
          <FlightFilter />
          <div className={styles.flight_listings}>
            {flights.map((flight, index) => (
              <Fragment key={index}>
                <FlightCard flight={flight} />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FlightsListingPage;
