import React from "react";
import styles from "../styles/FilterTag.module.css";

export const FilterTag = ({ label, isSelected, onClick }) => {
  return (
    <div
      className={`${styles.filterTag} ${isSelected ? styles.selected : ""}`}
      onClick={() => onClick(label)}
    >
      {label}
    </div>
  );
};
