import React from "react";
import styles from "./HotelBooking.module.css";
import { RoomServices } from "./components/RoomServices";
import { PriceDetails } from "./components/PriceDetails";

export function HotelBooking() {
  return (
    <div className={styles.bookingContainer}>
      <div className={styles.bookingCard}>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ee386a4fdef7f12887906602dd1d48038bc372d97f9adb4d943ba9f69671073?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
          alt="Sun Park Resort exterior view"
          className={styles.hotelImage}
        />
        <h1 className={styles.hotelName}>Sun Park Resort</h1>
        <div className={styles.divider} />

        <div className={styles.contentWrapper}>
          <div className={styles.ratingContainer}>
            <div className={styles.ratingWrapper}>
              <span className={styles.ratingScore}>4.3</span>
              <div className={styles.reviewDiv}>
                <span className={styles.ratingText}>Good</span>
                <span className={styles.reviewCount}>(1,801)</span>
              </div>
            </div>
            <button className={styles.reviewLink}>All Reviews</button>
          </div>

          <div className={styles.roomInfo}>
            <span className={styles.roomLabel}>1 Room:</span>
            <span className={styles.roomType}>Standard Room</span>
          </div>
          <span className={styles.bookingStatus}>Non-Refundable</span>
        </div>

        <div className={styles.divider} />

        <div className={styles.dateInfo}>
          <div className={styles.dateRow}>
            <span className={styles.dateLabel}>Check-in:</span>
            <span className={styles.dateValue}>1 Dec 2024</span>
          </div>
          <div className={styles.dateRow}>
            <span className={styles.dateLabel}>Check-out:</span>
            <span className={styles.dateValue}>3 Dec 2024</span>
          </div>
          <span className={styles.stayDuration}>2-night stay</span>
        </div>
      </div>

      <div>
        <RoomServices />
        <PriceDetails />
      </div>
    </div>
  );
}
