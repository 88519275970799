import React from "react";
import styles from "./PriceDetails.module.css";

const priceBreakdown = [
  { label: "1 room x 2 nights", value: 2000 },
  { label: "Taxes and fees", value: 1000 },
  { label: "Local tax", value: 999 },
];

const paymentOptions = [
  { label: "Pay now", value: 3999 },
  { label: "Pay at property", value: 1000 },
];

export function PriceDetails() {
  return (
    <div className={styles.priceCard}>
      <h2 className={styles.title}>Price details</h2>
      <div className={styles.divider} />

      {priceBreakdown.map((item, index) => (
        <div key={`price-${index}`} className={styles.priceRow}>
          <span className={styles.priceLabel}>{item.label}</span>
          <div className={styles.priceValue}>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/69b799f2c34c5b71be96425dd333ee3ef89650a21fa6ce6a564c2ef1d72e799c?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
              alt=""
              className={styles.currencyIcon}
            />
            <span>{item.value}</span>
          </div>
        </div>
      ))}

      <div className={styles.divider} />
      <div className={styles.totalRow}>
        <span>Total</span>
        <div className={styles.priceValue}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b190018e9f29b133a357add05dbba2565e7e872c458a2b53e4dad1b1b67be199?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
            alt=""
            className={styles.currencyIcon}
          />
          <span>4,999</span>
        </div>
      </div>
      <div className={styles.divider} />

      <div className={styles.paymentOptions}>
        <div>
          {paymentOptions.map((option, index) => (
            <div className={styles.totalPayRow} key={`payment-${index}`}>
              <span>{option.label}</span>
              <div className={styles.priceValue}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/edb7965c04c974db0dba757f4da87623ea6f1a8fda3e07c5eb5d57e384b0f049?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
                  alt=""
                  className={styles.currencyIcon}
                />
                <span>{option.value}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
