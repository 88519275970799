import React from "react";
import styles from "./FlightItineary.module.css";
import FlightTabs from "./FlightDetails/FlightTabs";
import { FlightSummary } from "../PassengerDetails/components/FlightSummary";
import { useDispatch } from "react-redux";
import { setJourneyProgress } from "../../../../features/CheckoutJourney/checkoutJourneySlice";
const FlightItineary = () => {
  const dispatch = useDispatch();
  const flight = {
    FlightDetails: {
      Airline: "Air India",
      FlightNumber: "AI-205/77W",
      TotalStops: "Non-Stop",
      Class: "Economy",
      Route: {
        Departure: {
          City: "Delhi",
          AirportCode: "DEL",
          Date: "4 Dec, Sat",
          Time: "06:45",
          Airport: "Indira Gandhi Intl Terminal 3",
        },
        Arrival: {
          City: "Manali",
          AirportCode: "KUU",
          Date: "6 Dec, Mon",
          Time: "08:10",
          Airport: "Bhuntar Airport Terminal 1",
        },
      },
      Duration: "1 hr 20 min",
      PriceOptions: [
        { price: "27,078", fareType: "Ndc Economy Saver" },
        { price: "27,078", fareType: "Ndc Economy Saver" },
        { price: "27,078", fareType: "Ndc Economy Saver" },
      ],
    },
    FareDetails: {
      BaseFare: 20000,
      TaxesAndFees: 5000,
      AirlineSurcharges: 2000,
      OptionalAddOns: {
        SeatSelection: "NA",
        MealPreferences: "NA",
        ExtraBaggage: "NA",
      },
      Total: 27078,
    },
    BaggageInformation: {
      CarryOnBaggage: {
        WeightLimit: "Max 7 Kg",
        ItemsAllowed:
          "Check your baggage allowances and fees to ensure you travel light.",
        ProhibitedItems:
          "Check your baggage allowances and fees to ensure you travel light.",
      },
      CheckedBaggage: {
        Allowance: "Max 25 Kg",
        ExcessBaggageFees: "Max 25 Kg",
        ProhibitedItems: "Max 25 Kg",
      },
    },
    CancellationAndRefundPolicy: {
      FreeCancellation: "Cancel within 24 hours of booking for a full refund.",
      After24Hours:
        "Cancellations made after 24 hours or within 7 days of departure will incur a fee of XX.",
      RefundProcess: "Refunds are processed within 10-14 business days.",
      CancellationFees:
        "Fee of XX for cancellations within 7 days of departure.",
    },
  };
  return (
    <div className={styles.container}>
      <main className={styles.mainContent}>
        <div className={styles.contentGrid}>
          <div className={styles.leftColumn}>
            <div className={styles.formContainer}>
              <FlightTabs flight={flight} />
              <div className={styles.actionButtons}>
                <div className="gap-x-[1rem] flex">
                  <button type="button" className={styles.backButton}>
                    Back
                  </button>
                </div>
                <button type="submit" className={styles.proceedButton} onClick={() => dispatch(setJourneyProgress(1))}>
                  Proceed to Add Passenger Information
                </button>
              </div>
            </div>
          </div>
          <div className={styles.rightColumn}>
            <FlightSummary />
          </div>
        </div>
      </main>
    </div>
  );
};

export default FlightItineary;
