import React from 'react';
import styles from './SeatBooking.module.css';
import { LegendItem } from './components/LegendItem';
import { PassengerCard } from './components/PassengerCard';

const SeatBooking = () => {
  const legendItems = [
    { type: 'selected', label: 'Selected' },
    { type: 'unavailable', label: 'Unavailable' },
    { type: 'available', label: 'Available' }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <div className={styles.flightInfo}>
          <div className={styles.airlineInfo}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4e45d98dd0f84a876768a656d309dc94e490ae2b8f4006198774a4c841678ad4?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
              className={styles.airlineLogo}
              alt="Air India logo"
            />
            <div className={styles.airlineName}>Air India</div>
          </div>
          <div className={styles.locationName}>Delhi</div>
        </div>
        <div className={styles.destinationInfo}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bb70c7f544eb8edf62f8464331400d8a4afd72e6ef5a2b75665254760fba407d?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
            className={styles.destinationIcon}
            alt="Destination icon"
          />
          <div className={styles.destinationName}>Manali</div>
          <div className={styles.flightDate}>Sat,Dec 4th 2024</div>
        </div>
      </div>

      <div className={styles.legendContainer}>
        {legendItems.map((item) => (
          <LegendItem key={item.type} type={item.type} label={item.label} />
        ))}
      </div>
      <PassengerCard>
        <div className={styles.passengerDetails}>
          <div>ADULT - 1</div>
          <div>C4</div>
          <div className={styles.priceInfo}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/08261fbff4646594645d46b141463dc7ffd50efc069aa213947f4f76ff5ef74d?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
              className={styles.currencyIcon}
              alt="Currency symbol"
            />
            <div>200</div>
          </div>
        </div>
      </PassengerCard>

      <div className={styles.actionButtons}>
        <button className={styles.skipButton}>
          Proceed without Seat Selection
        </button>
        <button className={styles.proceedButton}>Proceed</button>
      </div>
    </div>
  );
};

export default SeatBooking;