import React, { useState } from "react";
import RoomCard from "./RoomCard";
import styles from "./RoomSelector.module.css";
import { SelectedCategoryhotelRoom } from "../SelectedCategoryhotelRoom/SelectedCategoryhotelRoom";

const roomData = [
  { id: 1, title: "Super Deluxe Room (with Balcony)", price: "2,355" },
  {
    id: 2,
    title: "Studio Room-Centrally Heated (with Balcony)",
    price: "2,355",
  },
  { id: 3, title: "Honeymoon Room (with Balcony)", price: "2,355" },
  {
    id: 4,
    title: "Studio Room-Centrally Heated (with Balcony)",
    price: "2,355",
  },
  { id: 5, title: "Super Deluxe Room (with Balcony)", price: "2,355" },
  { id: 6, title: "Super Deluxe Room (with Balcony)", price: "2,355" },
];

export default function RoomSelector() {
  const [selectedRoomIds, setSelectedRoomIds] = useState([]);

  const handleRoomSelect = (id) => {
    setSelectedRoomIds((prevSelectedRoomIds) =>
      prevSelectedRoomIds.includes(id)
        ? prevSelectedRoomIds.filter((roomId) => roomId !== id)
        : [...prevSelectedRoomIds, id]
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.selectorWrapper}>
        <h1 className={styles.heading}>Select Room Type</h1>
        <div className={styles.divider} />

        <div className={styles.topGrid}>
          {roomData.map((room) => (
            <RoomCard
              key={room.id}
              {...room}
              isSelected={selectedRoomIds.includes(room.id)}
              onSelect={() => handleRoomSelect(room.id)}
            />
          ))}
        </div>
      </div>
      <br />
      <div className={styles.SelectCategoryWrapper}>
        {Array.from({ length: 8 }, (_, id) => (
          <SelectedCategoryhotelRoom />
        ))}
      </div>
    </div>
  );
}
