import React, { useState } from "react";

import styles from "./FlightDetails.module.css";
import { FlightDetailsTab } from "./FlightDetails/FlightDetailsTab";

const FlightTabs = ({ flight }) => {
  const [activeTab, setActiveTab] = useState("FlightDetails");

  const flightDetails = flight.FlightDetails;
  const flightFareDetails = flight.FareDetails;
  const flightBaggageInformation = flight.BaggageInformation;
  const flightCancellationAndRefundPolicy = flight.CancellationAndRefundPolicy;
  const tabContent = {
    FlightDetails: <FlightDetailsTab flightDetails={flightDetails} />,
    FareDetails: (
      <div className={styles.fareDetails}>
        <h3>Fare Details:</h3>
        <table>
          <tr className={styles.tableHeader}>
            <th className="text-left pl-[50px]">Item</th>
            <th>Amount(Currency)</th>
          </tr>
          <tr>
            <td className="text-left pl-[50px]">Base Fare:</td>
            <td className="text-center">₹{flightFareDetails?.BaseFare}</td>
          </tr>
          <tr>
            <td className="text-left pl-[50px]">Taxes and Fees:</td>
            <td className="text-center">₹{flightFareDetails?.TaxesAndFees}</td>
          </tr>
          <tr>
            <td className="text-left pl-[50px]">Airline Surcharges:</td>
            <td className="text-center">₹{flightFareDetails?.AirlineSurcharges}</td>
          </tr>
          <tr>
            <td className="text-left pl-[50px]">Total:</td>
            <td className={`text-center ${styles.totalPrice}`}> ₹{flightFareDetails?.Total}</td>
          </tr>
        </table>
      </div>
    ),
    BaggageInformation: (
      <div className={styles.baggageinfo}>
        <p>
          <b>Baggage Information:</b>{" "}
          Check your baggage allowances and fees to ensure you travel light and avoid any extra charges at the airport.
        </p>
        <div>
          <b>Carry-on Baggage:</b>{" "}
          <ul>
            <li><b>Weight Limit:</b>Max 7 Kg</li>
            <li><b>Items Allowed:</b>Check your baggage allowances and fees to ensure you travel light .</li>
            <li><b>Prohibited Items:</b>Check your baggage allowances and fees to ensure you travel light .</li>
          </ul>
        </div>
        <div>
          <b>Checked Baggage:</b>{" "}
          <ul>
            <li><b>Weight Limit:</b>Max 7 Kg</li>
            <li><b>Items Allowed:</b>Check your baggage allowances and fees to ensure you travel light .</li>
            <li><b>Prohibited Items:</b>Check your baggage allowances and fees to ensure you travel light .</li>
          </ul>
        </div>
      </div>
    ),
    CancellationAndRefundPolicy: (
      <div className={styles.cancellation}>
        <h3>Cancellation & Refund Policy</h3>
        <p>
          <b>Free Cancellation:</b>{" "}
          {flightCancellationAndRefundPolicy?.FreeCancellation}
        </p>
        <p>
          <b>After 24 Hours:</b>{" "}
          {flightCancellationAndRefundPolicy?.After24Hours}
        </p>
        <p>
          <b>Refund Process:</b>{" "}
          {flightCancellationAndRefundPolicy?.RefundProcess}
        </p>
        <p>
          <b>Cancellation Fees:</b>{" "}
          {flightCancellationAndRefundPolicy?.CancellationFees}
        </p>
      </div>
    ),
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${
            activeTab === "FlightDetails" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("FlightDetails")}
        >
          Flight Details
        </button>
        <button
          className={`${styles.tab} ${
            activeTab === "FareDetails" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("FareDetails")}
        >
          Fare Details
        </button>
        <button
          className={`${styles.tab} ${
            activeTab === "BaggageInformation" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("BaggageInformation")}
        >
          Baggage Information
        </button>
        <button
          className={`${styles.tab} ${
            activeTab === "CancellationAndRefundPolicy" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("CancellationAndRefundPolicy")}
        >
          Cancellation & Refund Policy
        </button>
      </div>
      <div className={styles.content}>{tabContent[activeTab]}</div>
    </div>
  );
};

export default FlightTabs;
