import React from 'react';
import styles from './PriceOption.module.css';

export const PriceOption = ({ price, fareType }) => {
  return (
    <>
      <div className={styles.priceRow}>
        <div className={styles.circle}>
          <div className={styles.innerCircle} />
        </div>
        <div className={styles.priceDisplay}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e82eef89ec4a5e801818c724d85c4b9b717c6195ca0701ee27313829c0c5256?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
            className={styles.currencyIcon}
            alt="Currency symbol"
          />
          <div>{price}</div>
        </div>
      </div>
      <div className={styles.fareType}>{fareType}</div>
      <div className={styles.divider} />
    </>
  );
};