import React from "react";
import { HotelImageGallery } from "../../hotel_components/Banner/HotelDetailBanner/Hotelgallery/HotelImageGallery";
import NavbarNew from "../../../Components/Navbar/NavbarNew";
import { RoomFeatures } from "./components/roomFeatures/RoomFeatures";
import { RoomFacilities } from "./components/roomFacilities/RoomFacilities";
import RoomTestimonials from "./components/testimonials/RoomTestimonials";
import { ReviewGallery } from "./components/ReviewGallery/ReviewGallery";
const RoomDetailPage = () => {
  return (
    <div>
      <NavbarNew />
      <HotelImageGallery />
      <RoomFeatures />
      <RoomFacilities />
      <RoomTestimonials />
      <ReviewGallery />
    </div>
  );
};

export default RoomDetailPage;
