import React, { Fragment, useState } from "react";
import "./HotelSearchFilter.css";
import { Search } from "lucide-react";
import HotelCalender from "../hotel_calender/HotelCalender";
import { useNavigate } from "react-router-dom";
import useHotelSearch from "../../../Hooks/useHotelSearch";

const HotelSearchFilter = () => {
  const navigate = useNavigate();
  const {
    openCalendar,
    isOpen,
    searchQuery,
    counts,
    checkInDate,
    checkOutDate,
    handleCheckInDateChange,
    handleCheckOutDateChange,
    toggleDropdown,
    setSearchQuery,
    updateCount,
    handleCalendarToggle,
    setIsOpen,
  } = useHotelSearch();

  const options = [
    { label: "Adults", key: "Adults" },
    { label: "Children", key: "Children" },
    { label: "Rooms", key: "Rooms" },
  ];

  const searchHotelList = () => {
    const params = {
      searchtext: searchQuery.searchtext,
      checkin: checkInDate,
      checkout: checkOutDate,
      acr: `${counts.Adults}${counts.Children}${counts.Rooms}`,
    };
    setSearchQuery(params);
    const queryParams = new URLSearchParams(params).toString();
    navigate(`/hotel-listing?${queryParams}`);
  };
  return (
    <div className="HotelSearchFilterwrapper">
      <div className="filterseachbar">
        <Search color="gray" />
        <input
          type="text"
          placeholder="Enter Hotel Name"
          onChange={(e) =>
            setSearchQuery((prev) => ({
              ...prev,
              searchtext: e.target.value,
            }))
          }
        />
      </div>
      <div className="filtercalender">
        <HotelCalender
          label={"Check In"}
          isOpen={openCalendar === "Check In"}
          onToggle={() => handleCalendarToggle("Check In")}
          onDateChange={handleCheckInDateChange}
        />
        <HotelCalender
          label={"Check Out"}
          isOpen={openCalendar === "Check Out"}
          onToggle={() => handleCalendarToggle("Check Out")}
          onDateChange={handleCheckOutDateChange}
        />
      </div>
      <div style={{ color: "white", position: "relative" }}>
        <p style={{ position: "relative", top: "-0.7rem" }}>Guest & Rooms</p>
        <div className="GuestRooms" onClick={toggleDropdown}>
          {counts.Adults} Adults, {counts.Children} Children, {counts.Rooms}{" "}
          Room
        </div>{" "}
        {isOpen && (
          <div className="grdiv">
            <div>
              <div>
                {options.map(({ label, key }) => (
                  <Fragment key={key}>
                    <div className="incdecwrap">
                      <span>{label}</span>
                      <div>
                        <button
                          className="decbtn"
                          onClick={() => updateCount(key, -1)}
                          disabled={
                            counts[key] <=
                            (key === "Adults" || key === "Rooms" ? 1 : 0)
                          }
                        >
                          -
                        </button>
                        {counts[key]}
                        <button
                          className="incbtn"
                          onClick={() => updateCount(key, 1)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <hr />
                  </Fragment>
                ))}
                <hr />
                <div className="paxsubmitwrapper">
                  <button onClick={() => setIsOpen(false)}>Done</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div onClick={() => searchHotelList()} className="searchfilterres">
        Search
      </div>
    </div>
  );
};

export default HotelSearchFilter;
