import React from "react";
import { HotelImageGallery } from "../../hotel_components/Banner/HotelDetailBanner/Hotelgallery/HotelImageGallery";
import NavbarNew from "../../../Components/Navbar/NavbarNew";
import HotelDetailSections from "./HotelDetailSections";
import RoomSelector from "./components/roomTypes/RoomSelector";

const HotelDetailPage = () => {
  return (
    <>
      <NavbarNew />
      <HotelImageGallery />
      <HotelDetailSections />
      <RoomSelector />
    </>
  );
};

export default HotelDetailPage;
