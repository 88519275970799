import React from 'react';
import styles from './FlightCard.module.css';

export const AirlineInfo = ({ logoSrc, airlineName, flightNumber }) => {
  return (
    <div className={styles.airlineLogo}>
      <img
        loading="lazy"
        src={logoSrc}
        className={styles.logo}
        alt={`${airlineName} logo`}
      />
      <div className={styles.airlineText}>
        <div className={styles.airlineName}>{airlineName}</div>
        <div className={styles.flightNumber}>{flightNumber}</div>
      </div>
    </div>
  );
};