import React from "react";
import { FacilitySection } from "./FacilitySection";
import styles from "./RoomFacilities.module.css";
import { useNavigate } from "react-router-dom";

const layoutItems = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Bed",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Window",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Desk",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Trash cans",
  },
];

const bathroomItems = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Bathrobes",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Towels",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Mirror",
  },
];

const comfortItems = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Air conditioning",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Fan",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Daily newspaper",
  },
];

const entertainmentItems = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Free Wi-Fi",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Telephone",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3c1856da638d4c6261c1c321393ac41b9f7fd4524eb67d710ff87e17cfb75df5?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    text: "Satellite/Cable channels",
  },
];

export const RoomFacilities = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>Room Facilities</h1>
        <div className={styles.divider} />

        <div className={styles.facilitiesGrid}>
          <div className={styles.facilitiesRow}>
            <FacilitySection
              title="Layout and furnishings"
              items={layoutItems}
            />
            <FacilitySection
              title="Bathroom and toiletries"
              items={bathroomItems}
            />
            <FacilitySection title="Comforts" items={comfortItems} />
            <FacilitySection title="Entertainment" items={entertainmentItems} />
          </div>
          <div className={styles.divider} />
          <div className={styles.facilitiesRow}>
            <FacilitySection
              title="Layout and furnishings"
              items={layoutItems}
            />
            <FacilitySection
              title="Bathroom and toiletries"
              items={bathroomItems}
            />
            <FacilitySection title="Comforts" items={comfortItems} />
            <FacilitySection title="Entertainment" items={entertainmentItems} />
          </div>
        </div>

        <div className={styles.actionButtons}>
          <button className={styles.moreButton}>+ More Services</button>
          <button
            onClick={() => navigate(`/hotel-listing/rooms/payment`)}
            className={styles.paymentButton}
          >
            Proceed Payment
          </button>
        </div>
      </div>
    </div>
  );
};
