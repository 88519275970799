import React from "react";
import styles from "../PassengerDetails.module.css";
import SideflightDetails from "./SideflightDetails";

export const FlightSummary = ({ flightDetails = false }) => {
  return (
    <div className={styles.flightDetails}>
      {flightDetails && <SideflightDetails />}

      <div className={styles.fareBox}>
        <h3>FARE SUMMARY</h3>
        <div className={styles.fareDivider} />
        <div className={styles.fareRow}>
          <span>Base Fare</span>
          <span>14,000</span>
        </div>
        <div className={styles.fareDivider} />
        <div className={styles.fareRow}>
          <span>Taxes and fees</span>
          <span>12,000</span>
        </div>
        <div className={styles.fareDivider} />
        <div className={styles.fareRow}>
          <span>Amount to Pay</span>
          <span className={styles.totalAmount}>26,000</span>
        </div>
        <div className={styles.fareDivider} />
      </div>
    </div>
  );
};
