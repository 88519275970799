import React from "react";
import styles from "../styles/FilterButton.module.css";

export const FilterButton = ({ icon, label }) => {
  return (
    <div className={styles.filterButton}>
      <img loading="lazy" src={icon} alt="" className={styles.filterIcon} />
      <div>{label}</div>
    </div>
  );
};
