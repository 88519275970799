import React from "react";
import styles from "../PaymentForm.module.css";

export const DateSelect = ({ label, icon }) => {
  return (
    <div className={styles.dateSelect}>
      <div>{label}</div>
      <img loading="lazy" src={icon} className={styles.dropdownIcon} alt="" />
    </div>
  );
};
