import React from "react";
import styles from "../PassengerDetails.module.css";
import { useDispatch } from "react-redux";
import { setJourneyProgress, setSeatPopUp } from "../../../../../features/CheckoutJourney/checkoutJourneySlice";

export const PassengerForm = () => {
  const dispatch = useDispatch();
  return (
    <div className={styles.formContainer}>
      <div className={styles.formGrid}>
        <div>
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <div className={styles.inputGroup}>
                <label htmlFor="firstName" className={styles.inputLabel}>
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  className={styles.inputField}
                />
              </div>
            </div>
            <div className={styles.formColumn}>
              <div className={styles.inputGroup}>
                <label htmlFor="middleName" className={styles.inputLabel}>
                  Middle Name
                </label>
                <input
                  type="text"
                  id="middleName"
                  className={styles.inputField}
                />
              </div>
            </div>
            <div className={styles.formColumn}>
              <div className={styles.inputGroup}>
                <label htmlFor="lastName" className={styles.inputLabel}>
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  className={styles.inputField}
                />
              </div>
            </div>
          </div>

          <label htmlFor="confirmation" className={styles.inputLabel}>
            Email Address
          </label>
          <input
            type="text"
            id="confirmation"
            className={styles.confirmationInput}
            placeholder="Enter the Confirmation"
          />

          <h2 className={styles.passportSection}>Add Passport Information</h2>
          <div className={styles.passportGrid}>
            <div className={styles.inputGroup}>
              <label htmlFor="passportNo">Passport No.</label>
              <input
                type="text"
                id="passportNo"
                className={styles.inputField}
                placeholder="Type here"
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="issueDate">Issue Date</label>
              <input type="date" id="issueDate" className={styles.inputField} />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="expiryDate">Expiry Date</label>
              <input
                type="date"
                id="expiryDate"
                className={styles.inputField}
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="dateOfBirth">Date of Birth</label>
              <input
                type="date"
                id="dateOfBirth"
                className={styles.inputField}
              />
            </div>
          </div>

          <div className={styles.contactGrid}>
            <div className={styles.mobileNoDetails}>
              <label htmlFor="countryCode">Country Code</label>
              <select
                name="countryCode"
                id="countryCode"
                className={styles.inputField}
              >
                <option value="India">(India +91)</option>
                <option value="Pak">(Pak +92)</option>
              </select>
            </div>
            <div>
              <label htmlFor="mobileNo">Mobile no.</label>
              <input
                type="tel"
                name="mobileNo"
                id="mobileNo"
                className={styles.inputField}
              />
            </div>
          </div>
        </div>

        <div className={styles.saveCheckbox}>
          <input type="checkbox" id="saveDetails" />
          <label htmlFor="saveDetails">Save Passenger Details</label>
          <button type="button" className={styles.addPassenger}>
            + Passenger
          </button>
        </div>

        <div className={styles.actionButtons}>
          <div className="gap-x-[1rem] flex">
            <button type="button" className={styles.backButton}>
              Back
            </button>
            <button type="button" className={styles.addMealSeat} onClick={() => dispatch(setSeatPopUp(true))}>
              Select Seat/Meal/Baggage
            </button>
          </div>
          <button type="submit" className={styles.proceedButton} onClick={() => dispatch(setJourneyProgress(2))}>
            Proceed To Review
          </button>
        </div>
      </div>
    </div>
  );
};
