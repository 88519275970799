import React from "react";
import styles from "./FlightCard.module.css";

const SideflightDetails = () => {
  return (
    <div className={styles.card}>
      {/* Airline and Route Info */}
      <div className={styles.header}>
        <div className={styles.airline}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/227318a0a5b4920c2c1c2dd48527b163ef0b0b13c7448023e0205a95089301b4?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141" // Replace with airline logo URL
            alt="Air India"
            className={styles.logo}
          />
          <span>Air India</span>
        </div>
        <div className={styles.route}>
          <span>Delhi → Manali</span>
          <span>Sat, Dec 4th 2024</span>
        </div>
      </div>

      {/* Flight Details */}
      <div className={styles.details}>
        <div className={styles.flightSegment}>
          <div>
            <strong>4 Dec, Sat, 06:45</strong>
            <p>DEL, India</p>
            <p>Delhi Indira Gandhi Intl</p>
            <p>Terminal 3</p>
          </div>
          <div className={styles.duration}>
            <p>1h 20m</p>
            <span className={styles.arrow}>→</span>
          </div>
          <div>
            <strong>6 Dec, Mon, 08:10</strong>
            <p>KUU, India</p>
            <p>Bhuntar Airport</p>
            <p>Terminal 1</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideflightDetails