import React, { useState } from "react";
import "./BookFlightCard.css";
import FlightInterchange from "../../Assets/flightsInterchange.svg";
import DateSelectorFlights from "./DateSelectorFlights";
import FlightSearchDropdown from "./FlightSearchDropdown";
import FlightPaxNAdults from "./flightPaxNadults";
import { useNavigate } from "react-router-dom";

const BookFlightCard = () => {
  const [selectRadio, setSelectRadio] = useState("oneWay");
  const [openCalendar, setOpenCalendar] = useState(null);
  const [showDropdown, setShowDropdown] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [flightData, setFlightData] = useState({
    flightPaxNChilds: { Adults: 2, Children: 0, Infants: 0 },
    flightDepartDate: null,
    flightReturnDate: null,
    flightTo: {
      city: "New Delhi, India",
      airportCode: "DEL",
      airportName: "Indira Gandhi International Airport",
    },
    flightFrom: {
      city: "Ahmedabad, India",
      airportCode: "AMD",
      airportName: "Sardar Vallabhbhai Patel International Airport",
    },
  });

  const [flightFieldErrors, setFlightFieldieldErrors] = useState({
    flightDepartDate: false,
    flightReturnDate: false,
    flightTo: false,
    flightFrom: false,
  });
  function generateFlightUrl() {
    const formatDate = (date) => {
      if (!date) return null;
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    };

    const {
      flightPaxNChilds,
      flightDepartDate,
      flightReturnDate,
      flightTo,
      flightFrom,
    } = flightData;

    const itinerary =
      selectRadio === "oneWay"
        ? `${flightTo.airportCode}-${flightFrom.airportCode}-${formatDate(
            flightDepartDate
          )}`
        : `${flightTo.airportCode}-${flightFrom.airportCode}-${formatDate(
            flightDepartDate
          )}/${formatDate(flightReturnDate)}`;

    const paxType = `A-${flightPaxNChilds.Adults}_C-${flightPaxNChilds.Children}_I-${flightPaxNChilds.Infants}`;

    const url = `?itinerary=${itinerary}&tripType=${selectRadio}&paxType=${paxType}`;
    return url;
  }
  const searchFlights = () => {
    const errors = {
      flightDepartDate: !flightData.flightDepartDate,
      flightReturnDate:
        selectRadio === "return" && !flightData.flightReturnDate,
      flightTo: !flightData.flightTo?.city || !flightData.flightTo?.airportCode,
      flightFrom:
        !flightData.flightFrom?.city || !flightData.flightFrom?.airportCode,
    };

    setFlightFieldieldErrors(errors);
    const hasError = Object.values(errors).some((value) => value);
    if (!hasError) {
      console.log("Form is valid. Submitting data:", flightData);
      navigate(`/flights/flightlisting${generateFlightUrl()}`);
    } else {
      console.log("Validation failed. Fix errors.");
    }
  };

  const openSearchFlight = (value) => setShowDropdown(value);
  const closeSearchFlight = () => setTimeout(() => setShowDropdown(""), 200);

  const flightSelection = (type, data) => {
    if (flightData.flightReturnDate && type === "flightDepartDate") {
      if (flightData.flightReturnDate < data) {
        setFlightData((prevData) => ({
          ...prevData,
          flightReturnDate: null,
        }));
      }
    }
    setFlightData((prevData) => ({
      ...prevData,
      [type]: data,
    }));
    closeSearchFlight();
    setFlightFieldieldErrors({
      flightDepartDate: false,
      flightReturnDate: false,
      flightTo: false,
      flightFrom: false,
    });
  };

  const swapFromAndTo = (e) => {
    e.stopPropagation();
    setFlightData((prevData) => ({
      ...prevData,
      flightFrom: prevData.flightTo,
      flightTo: prevData.flightFrom,
    }));
  };
  const passengerInfo = [
    flightData.flightPaxNChilds.Adults > 0
      ? `${flightData.flightPaxNChilds.Adults} Adults`
      : "",
    flightData.flightPaxNChilds.Children > 0
      ? `${flightData.flightPaxNChilds.Children} Childs`
      : "",
    flightData.flightPaxNChilds.Infants > 0
      ? `${flightData.flightPaxNChilds.Infants} Infants`
      : "",
  ]
    .filter(Boolean)
    .join(", ");

  const toggleDropdown = () => setIsOpen(!isOpen);
  const changingReturn = (data) => {
    if (data === "return")
      setFlightData((prevData) => ({
        ...prevData,
        flightReturnDate: null,
      }));
    setSelectRadio(data);
  };

  return (
    <div className="book-flight-card">
      <div className="book-flight-card__trip-options">
        <label
          onClick={() => changingReturn("return")}
          className={`${selectRadio === "return" ? "selected" : ""}`}
        >
          <span></span>Return
        </label>
        <label
          onClick={() => changingReturn("oneWay")}
          className={`${selectRadio === "oneWay" ? "selected" : ""}`}
        >
          <span></span> One way
        </label>
        <label
          onClick={() => changingReturn("multiCity")}
          className={`${selectRadio === "multiCity" ? "selected" : ""}`}
        >
          <span></span> Multi-City
        </label>
      </div>

      <div className="book-flight-card__form-fields">
        <div
          className="book-flight-card__form-group"
          onClick={() => openSearchFlight("from")}
        >
          <label>From</label>
          <span className="flight_labels">{flightData.flightFrom.city}</span>
          <p className="flight_labels">
            {flightData.flightFrom.airportCode},{" "}
            {flightData.flightFrom.airportName.slice(0, 15)}...
          </p>

          {showDropdown === "from" && (
            <FlightSearchDropdown
              onFlightSelection={(data) => flightSelection("flightFrom", data)}
            />
          )}
          <img
            src={FlightInterchange}
            onClick={swapFromAndTo}
            alt="Swap"
            className="book-flight-card__flightsInterchange"
          />
          {flightFieldErrors.flightFrom && (
            <span className="error-text">This field is required.</span>
          )}
        </div>
        <div
          className="book-flight-card__form-group"
          onClick={() => openSearchFlight("to")}
        >
          <label>To</label>
          <span className="flight_labels">{flightData.flightTo.city}</span>
          <p className="flight_labels">
            {flightData.flightTo.airportCode},{" "}
            {flightData.flightTo.airportName.slice(0, 15)}...
          </p>

          {showDropdown === "to" && (
            <FlightSearchDropdown
              onFlightSelection={(data) => flightSelection("flightTo", data)}
            />
          )}
          {flightFieldErrors.flightTo && (
            <span className="error-text">This field is required.</span>
          )}
        </div>

        {/* Depart Date */}
        <div
          className="book-flight-card__form-group"
          onClick={() => setOpenCalendar("depart")}
        >
          <label>Depart</label>
          <div className="calendar-popup">
            <div className="date-input">
              {flightData.flightDepartDate
                ? flightData.flightDepartDate.toLocaleDateString("en-GB")
                : "Select a Date"}
            </div>
            {openCalendar === "depart" && (
              <DateSelectorFlights
                onFlightSelection={(data) =>
                  flightSelection("flightDepartDate", data)
                }
                onClosePopup={() => setOpenCalendar(null)}
                selectedDate={flightData.flightDepartDate}
              />
            )}
          </div>
          {flightFieldErrors.flightDepartDate && (
            <span className="error-text">This field is required.</span>
          )}
        </div>

        {/* Return Date */}
        <div
          className="book-flight-card__form-group"
          onClick={() => setOpenCalendar("return")}
        >
          <label>Return</label>
          {selectRadio !== "oneWay" ? (
            <div className="calendar-popup">
              <div className="date-input">
                {flightData.flightReturnDate
                  ? flightData.flightReturnDate.toLocaleDateString("en-GB")
                  : "Select a Date"}
              </div>
              {openCalendar === "return" && (
                <DateSelectorFlights
                  onFlightSelection={(data) =>
                    flightSelection("flightReturnDate", data)
                  }
                  onClosePopup={() => setOpenCalendar(null)}
                  selectedDate={flightData.flightReturnDate}
                  departDate={flightData.flightDepartDate}
                />
              )}
            </div>
          ) : (
            <div
              onClick={() => changingReturn("return")}
              className="flight_labels"
            >
              Tap to add a return
            </div>
          )}
          {flightFieldErrors.flightReturnDate && selectRadio === "return" && (
            <span className="error-text">This field is required.</span>
          )}
        </div>

        {/* Travellers and cabin class */}
        <div className="book-flight-card__form-group" onClick={toggleDropdown}>
          <div>
            <label>Travellers and cabin class</label>
            <p className="flight_labels">{passengerInfo}</p>
          </div>
          {isOpen && (
            <FlightPaxNAdults
              onToggleDropdown={toggleDropdown}
              onAdultsNChildSelect={(data) =>
                flightSelection("flightPaxNChilds", data)
              }
              paxNChildsValues={flightData.flightPaxNChilds}
            />
          )}
        </div>

        {/* Search Button */}
        <button
          className="book-flight-card__search-button"
          onClick={searchFlights}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default BookFlightCard;
