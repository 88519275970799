import React from "react";
import styles from "./ImageGallery.module.css";
import { HotelGalleryImage } from "./HotelGalleryImage";

const galleryData = [
  {
    id: 1,
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/a596ce376c1660cd7fdeeaf2a9f0cf117ce7367362428d471a385e4bc14f0ae1?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    alt: "Main gallery image",
  },
  {
    id: 2,
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/90150e2691272788aa51735afab0dcd52113959886c310c2515608574479274f?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    alt: "Gallery thumbnail 1",
  },
  {
    id: 3,
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/6b570e685a7b348cf8a2c9de0c599083c0c1b9844cf453c2affaa08bb5b22b13?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    alt: "Gallery thumbnail 2",
  },
  {
    id: 4,
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/6055302bd662ad6496e8eb9896ee7fba01546e9a791ba89b5b03e34a99aca824?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    alt: "Gallery thumbnail 3",
  },
  {
    id: 5,
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/92c8aff8f117cbd67634cbe4f8f879d939b0553571962b54297eea3016870f78?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2",
    alt: "Gallery thumbnail 4",
  },
];

export const HotelImageGallery = () => {
  return (
    <div className={styles.galleryContainer}>
      <div className={styles.galleryGrid}>
        <div className={styles.mainImageColumn}>
          <div className={styles.mainImageColumn1}>
            <HotelGalleryImage
              src={galleryData[0].src}
              className={styles.mainImage}
              alt={galleryData[0].alt}
            />
          </div>
        </div>
        <div className={styles.thumbnailColumn}>
          <div className={styles.thumbnailGrid}>
            <div className={styles.thumbnailRow}>
              <div className={styles.thumbImageColumn1}>
                <HotelGalleryImage
                  src={galleryData[1].src}
                  className={styles.thumbnailImage}
                  alt={galleryData[1].alt}
                />
              </div>

              <div className={styles.thumbImageColumn1}>
                <HotelGalleryImage
                  src={galleryData[2].src}
                  className={styles.thumbnailImage}
                  alt={galleryData[2].alt}
                />
              </div>
            </div>
            <div className={styles.thumbnailRow}>
              <div className={styles.thumbImageColumn1}>
                <HotelGalleryImage
                  src={galleryData[3].src}
                  className={styles.thumbnailImage}
                  alt={galleryData[3].alt}
                />
              </div>
              <div className={styles.thumbImageColumn1}>
                <HotelGalleryImage
                  src={galleryData[4].src}
                  className={styles.thumbnailImage}
                  alt={galleryData[4].alt}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
