import React from "react";
import { LandmarkItem } from "./LandmarkItem";
import styles from "./ReviewCard.module.css";

const landmarks = [
  { name: "Border Road Organization Camp", distance: "330m" },
  { name: "Tibetan Monastery", distance: "830m" },
  { name: "Aleo", distance: "470m" },
  { name: "Van Vihar National Park", distance: "650m" },
  { name: "Civil Hospital", distance: "700m" },
];

export const ReviewCard = () => {
  return (
    <div className={styles.reviewContainer}>
      <div className={styles.ratingSection}>
        <div className={styles.ratingWrapper}>
          <div className={styles.ratingScore}>4.6</div>
          <div className={styles.ratingText}>Very Good</div>
          <div className={styles.ratingCount}>(4322 ratings)</div>
        </div>
        <div className={styles.reviewLink}>All Reviews</div>
      </div>

      <div className={styles.landmarksContainer}>
        <div className={styles.landmarksContent}>
          <div className={styles.landmarksInfo}>
            <div className={styles.landmarksList}>
              <div className={styles.landmarksTitle}>Closest Landmarks</div>
              {landmarks.map((landmark, index) => (
                <LandmarkItem
                  key={index}
                  name={landmark.name}
                  distance={landmark.distance}
                />
              ))}
              <div className={styles.parkingSection}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9f827e5a750d81599bff1460a11bd2a0cd62ccd0edb6fbb30cf9923a3d970a0?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
                  alt="Parking icon"
                  className={styles.parkingIcon}
                />
                <div>Parking</div>
              </div>
            </div>
          </div>
          <div className={styles.distanceColumn}>
            <div className={styles.distanceList}>
              {landmarks.map((landmark, index) => (
                <div key={index}>{landmark.distance}</div>
              ))}
              <div className={styles.parkingStatus}>FREE</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
