import React from 'react';
import styles from '../SeatBooking.module.css';

export const PassengerCard = ({ header, children }) => {
  return (
    <div className={styles.passengerSection}>
      <div className={styles.sectionHeader}>
        <div>Passenger </div>
        <div>Seat</div>
        <div>Charges</div>
      </div>
      <div className={styles.divider} />
      {children}
    </div>
  );
};