import React from 'react';
import styles from './FlightFilter.module.css';
import { FilterOptions } from './FilterOptions';

export const FilterSection = ({ title, options }) => {
  return (
    <div className={styles.filterSection}>
      <h4 className={styles.sectionTitle}>{title}</h4>
      {options.map((option, index) => (
        <FilterOptions key={index} label={option} />
      ))}
    </div>
  );
};