import React from 'react';
import styles from './FlightFilter.module.css';

export const FilterOptions = ({ label }) => {
  return (
    <div className={styles.optionContainer}>
      <div className={styles.checkbox} role="checkbox" tabIndex={0} aria-label={label} />
      <div className={styles.checkbox_text}>{label}</div>
    </div>
  );
};