import React from "react";
import styles from "./HotelCard.module.css";

export function PriceDisplay({ mainPrice, taxesAndFees }) {
  return (
    <div className={styles.priceContainer}>
      <div className={styles.mainPrice}>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e7826a1abf6a9821a88fa72570a6117a6c5174677fdc3c24535fe0bb6eeeb9a?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491"
          className={styles.currencyIcon}
          alt=""
        />
        <div>{mainPrice.toLocaleString()}</div>
      </div>
      <div className={styles.priceDetails}>
        <div className={styles.taxesText}>
          <span className={styles.plusSign}>+</span> {taxesAndFees} taxes
          &fees/Night
        </div>
        <button className={styles.bookButton}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bbd9ac02-b258-48f4-a992-6e1adfc57276?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491"
            className={styles.buttonBackground}
            alt=""
          />
          <span>{mainPrice.toLocaleString()}</span>
        </button>
      </div>
    </div>
  );
}
