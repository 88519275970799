import React from "react";
import styles from "./Seat.module.css";
import SeatBooking from "./SeatBooking";
import SeatMatrix from "./components/SeatMatrix";

const Seat = () => {
  const rows = [
    [
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { empty: true },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000", selected:'unavailable' },
      { type: "Seat", color: "#FFD70000", selected:'unavailable' },
    ],
    [
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000", selected:'unavailable' },
      { type: "Seat", color: "#FFD70000", selected:'unavailable' },
      { empty: true },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000", selected:'selected' },
      { type: "Seat", color: "#FFD70000" },
    ],
    [
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { empty: true },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
    ],
    [
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { empty: true },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
    ],
    [
      { type: "exit", color: "#FFD70000" },
      { type: "exit", color: "#FFD70000" },
      { type: "exit", color: "#FFD70000" },
      { empty: true },
      { type: "exit", color: "#FFD70000" },
      { type: "exit", color: "#FFD70000" },
      { type: "exit", color: "#FFD70000" },
    ],
    [
      { type: "exit", color: "#FFD70000" },
      { type: "exit", color: "#FFD70000" },
      { type: "exit", color: "#FFD70000" },
      { empty: true },
      { type: "exit", color: "#FFD70000" },
      { type: "exit", color: "#FFD70000" },
      { type: "exit", color: "#FFD70000" },
    ],
    [
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { empty: true },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
    ],
    [
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { empty: true },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
    ],
    [
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { empty: true },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
    ],
    [
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { empty: true },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
      { type: "Seat", color: "#FFD70000" },
    ],
  ];

  return (
    <div className={styles.container}>
      <div className={styles.selectionSummary}>
        <SeatBooking />
      </div>
      <div className={styles.seatMap}>
        <SeatMatrix rows={rows}/>
      </div>
    </div>
  );
};

export default Seat;
