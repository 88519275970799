import React, { useState } from "react";
import styles from "./ProgressBar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setJourneyProgress } from "../../../../../features/CheckoutJourney/checkoutJourneySlice";

const ProgressBar = ({ currentStep = 0}) => {
    const dispatch = useDispatch()
    const stepsCount = useSelector((state) => state.checkoutJourney.journeyProgress)
    const steps = [
         "Flight Itinerary",
         "Passenger Details",
         "Review",
         "Payment",
      ];
  return (
    <div className={styles.progressBar}>
      {steps.map((step, index) => (
        <div key={index} className={styles.step}>
          <div
            className={`${styles.circle} ${
              index <= stepsCount ? styles.completed : ""
            }`}
            onClick={() => dispatch(setJourneyProgress(index))}
          >
            {index <= stepsCount ? <span>&#10003;</span> : ""}
          </div>
          <span
            className={`${styles.label} ${
              index <= stepsCount ? styles.activeLabel : ""
            }`}
          >
            {step}
          </span>
          {index < steps.length - 1 && (
            <div
              className={`${styles.line} ${
                index <= stepsCount - 1 ? styles.completed : ""
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
