import React from "react";
import styles from "./Filters.module.css";

export const StarRating = ({ stars }) => {
  return (
    <div className={styles.starContainer}>
      {Array(stars)
        .fill()
        .map((_, index) => (
          <img
            key={index}
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9b91da5d854e30c43192c42f4cdf8c79f2001d5f0ae632a5815da764846d787d?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
            alt=""
            className={styles.starIcon}
          />
        ))}
    </div>
  );
};
