import React from 'react';
import styles from './RouteDisplay.module.css';

const RouteDisplay = () => {
  return (
    <div className={styles.routeContainer}>
      <div className={styles.route}>
        <span className={styles.city}>Delhi</span>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca53ea5afeca8eb81e0dc0d118cd2aef4abb6032a4d5b28b0940190bebc6f78c?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
          alt="Route line"
          className={styles.routeLine}
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/bb70c7f544eb8edf62f8464331400d8a4afd72e6ef5a2b75665254760fba407d?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
          alt=""
          className={styles.routeIcon}
        />
        <span className={styles.city}>Manali</span>
      </div>
      <div className={styles.mealTypes}>
        <div className={styles.mealType}>
          <input type='checkbox' className={styles.indicator} />
          <span>Veg</span>
        </div>
        <div className={styles.mealType}>
          <input type='checkbox' className={styles.indicator} />
          <span>Non-Veg</span>
        </div>
        <div className={styles.mealType}>
          <input type='checkbox' className={styles.indicator} />
          <span>Vegan</span>
        </div>
      </div>
    </div>
  );
};
export default RouteDisplay;