import React from "react";
import { FacilityItem } from "./FacilityItem";
import styles from "./RoomFacilities.module.css";

export const FacilitySection = ({ title, items }) => {
  return (
    <div className={styles.facilitySection}>
      <div className={styles.sectionTitle}>{title}</div>
      {items.map((item, index) => (
        <FacilityItem key={index} icon={item.icon} text={item.text} />
      ))}
    </div>
  );
};
