import React from "react";
import NavbarNew from "../../../Components/Navbar/NavbarNew";
import { CheckInForm } from "./checkIn/CheckInForm";
import "./HotelPayment.css";
import { HotelBooking } from "./hotelBooking/HotelBooking";
import { PaymentForm } from "./payment/PaymentForm";
const HotelPayment = () => {
  return (
    <div>
      <NavbarNew />
      <div className="paymentwarpper">
        <div>
          <CheckInForm />
          <PaymentForm />
        </div>

        <HotelBooking />
      </div>
    </div>
  );
};

export default HotelPayment;
