import React from "react";
import styles from "./HotelCard.module.css";

export const RatingIcon = ({ src }) => {
  return (
    <img
      loading="lazy"
      src={src}
      className={styles.ratingIcon}
      alt="Hotel rating icon"
    />
  );
};
