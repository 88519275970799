import React, { useState } from "react";
import "./Flights.css";
import BookFlightCard from "./BookFlightCard";
const FlightsHeader = () => {
  return (
    <div className="flight-booking-containers">
      <h1 className="flight-heading">
        "Book Your Flight and Grab the Best Airfare Deals!"
      </h1>
      <div className="flight-booking-form">
        <BookFlightCard />

        <div className="book-flight-card__checkbox-group">
          <label>
            <input type="checkbox" /> Add nearby airports
          </label>
          <label>
            <input type="checkbox" /> Direct flights only
          </label>
        </div>
      </div>
    </div>
  );
};

export default FlightsHeader;
