import { createSlice } from '@reduxjs/toolkit';

const checkoutJourney = createSlice({
  name: 'CheckoutJourney',
  initialState: {
    seatData: null,
    seatPopUp: false,
    journeyProgress: 0,
  },
  reducers: {
    setSeatData: (state, action) => {
      state.data = action.payload;
    },
    setSeatPopUp: (state, action) => {
      state.seatPopUp = action.payload;
    },
    setJourneyProgress: (state, action) =>{
      state.journeyProgress = action.payload
    }
  },
});

export const { setSeatData, setSeatPopUp, setJourneyProgress } = checkoutJourney.actions;

export default checkoutJourney.reducer;
