import React from "react";
import HotelBanner from "../hotel_components/Banner/HotelBanner";
import NavbarNew from "../../Components/Navbar/NavbarNew";
import useWindowSize from "../../Hooks/useWindowSize";
import { HotelMobileSearch } from "../[Mobile]/HotelMobileSearch/HotelMobileSearch";

const HotelMainPage = () => {
  const size = useWindowSize();
  return (
    <div>
      {size.width > 468 ? (
        <>
          <NavbarNew />
          <HotelBanner />
        </>
      ) : (
        <HotelMobileSearch />
      )}
    </div>
  );
};

export default HotelMainPage;
