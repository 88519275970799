import React from "react";
import styles from "./FoodCard.module.css";

const FoodCard = ({ title, price, imageSrc }) => {
  return (
    <>
      <div className={styles.foodCardContainer}>
        <img src={imageSrc} alt="" className={styles.foodCarMainImg}/>
        <div className={styles.foodCard}>
          <div className={styles.cardContent}>
            <div className={styles.titlePrice}>
              <h3 className={styles.menuItemTitle}>{title}</h3>
              <button className={styles.addButton}>Add</button>
            </div>
            <div className={styles.priceWrapper}>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/8651405268146f54c8fb53af5610242ae167eaa6712b8d4d37935b81d3333071?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
                alt=""
                className={styles.currencyIcon}
              />
              <span className={styles.price}>{price}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FoodCard;
