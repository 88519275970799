import React from "react";
import styles from "./SeatMatrix.module.css";
import SeatChair from "./SeatChair";
import flightHeader from "../../../../../../Assets/flightHeader.svg";

const SeatMatrix = ({ rows }) => {
  const getSeatLabel = (rowIndex, seatIndex) => {
    // debugger
    console.log(rowIndex, seatIndex);
    let seatLabel;
    switch (seatIndex) {
      case 0:
        seatLabel = "A";
        break;
      case 1:
        seatLabel = "B";
        break;
      case 2:
        seatLabel = "C";
        break;
      case 4:
        seatLabel = "D";
        break;
      case 5:
        seatLabel = "E";
        break;
      case 6:
        seatLabel = "F";
        break;
      default:
        seatLabel = "";
        break;
    }

    // Map row index to number and seat index to the letter
    const rowLabel = rowIndex + 1; // Assuming rows start from 1
    // const seatLabel = seatMap[seatIndex];

    return `${seatLabel}${rowLabel}`;
  };
  return (
    <div className={styles.outerContainer}>
      <div className={styles.flightWrap}>
        <div className={styles.iconflFront}>
          <img src={flightHeader} alt="" />
        </div>
        <div className={styles.flightSeatMatrix}>
          {rows.map((row, rowIndex) => (
            <div key={rowIndex} className={styles.seatRow}>
              {row.map((seat, seatIndex) => (
                <div
                  key={seatIndex}
                  className={`${styles.seatCol} ${
                    seat.empty ? styles.seatEmpty : ""
                  }`}
                  style={{ margin: "2.8px" }}
                >
                  {seat.empty ? (
                    <div className={styles.seatEmptyLabel}></div>
                  ) : (
                    <div
                      className={`${styles.seatBlock} ${
                        seat.type === "exit" ? styles.noReclineExitSeat : ""
                      }`}
                      style={{ backgroundColor: seat.color }}
                    >
                      <span>{getSeatLabel(rowIndex, seatIndex)}</span>
                      {(seat.type === "exit" || seat.type === "Seat") && (
                        <SeatChair seatselection={seat.selected} />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeatMatrix;
