import React from 'react';
import styles from '../SeatBooking.module.css';

export const LegendItem = ({ type, label }) => {
  const dotClassName = `${styles.legendDot} ${styles[`${type}Dot`]}`;
  
  return (
    <div className={styles.legendItem}>
      <div className={dotClassName} />
      <div>{label}</div>
    </div>
  );
};