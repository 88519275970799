import React from 'react';
import styles from './Baggage.module.css';

const baggageOptions = [
  { weight: 3, price: '1,200', iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/047ff8198993244b255b957d141dafc1d658b5c97dfb767e0aa4a14112602baa?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141' },
  { weight: 5, price: '2,290', iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/cc951bacc6b6f652ca929098eb33cfeeab9397b27410d2b00fb61c321e02cd51?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141' },
  { weight: 10, price: '4,120', iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/3d38f424-bb94-4015-b69a-ea34794880bf?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141' },
  { weight: 15, price: '6,400', iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/9b5d02e8-cfd2-423c-a40a-860e706bc52d?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141' },
  { weight: 20, price: '8,340', iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/d1f31815-84b7-40ca-a76b-7f8640b0ed56?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141' }
];

const Baggage = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Add Extra Baggage:</h1>
      <h2 className={styles.subtitle}>
        Including Check-in baggage per person - 25 KGS
      </h2>
      <div className={styles.optionsContainer}>
        <div className={styles.optionsWrapper}>
          {baggageOptions.map((option, index) => (
            <div className={styles.baggageOption} key={index}>
            <img 
              loading="lazy" 
              src={option.iconSrc} 
              className={styles.baggageIcon}
              alt={`Baggage icon for ${option.weight} KG`} 
            />
            <div className={styles.baggageText}>Additional {option.weight} KG</div>
            <div className={styles.priceSection}>
              <div className={styles.priceWrapper}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/8651405268146f54c8fb53af5610242ae167eaa6712b8d4d37935b81d3333071?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
                  className={styles.currencyIcon}
                  alt=""
                />
                <div>{option.price}</div>
              </div>
              <button className={styles.addButton}>Add</button>
            </div>
          </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Baggage