import React from "react";
import { AmenityItem } from "./AmenityItem";
import { RestrictionItem } from "./RestrictionItem";
import styles from "./HotelRoom.module.css";
import { useNavigate } from "react-router-dom";

const amenities = [
  "Free breakfast",
  "Free self parking",
  "Free Wi-Fi",
  "Free breakfast",
  "Free breakfast",
];

const restrictions = [
  "Non refundable",
  "Mineral water - Additional charge",
  "Heater - Additional charges",
  "Heater - Additional charges",
];

export const SelectedCategoryhotelRoom = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.roomCard}>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2ab842a661b8a87a91c1d1eb2af0c0075de7d1047033ead2e9708b10030907bf?placeholderIfAbsent=true&apiKey=97b3eb30615d400e881ed06a5c13edd2"
          className={styles.roomImage}
          alt="Studio Room with Balcony"
        />
        <h2 className={styles.roomTitle}>
          Studio Room-Centrally Heated(with Balcony)
        </h2>
        <div className={styles.contentWrapper}>
          <div className={styles.twoColumnLayout}>
            <div className={styles.amenitiesColumn}>
              <div className={styles.amenitiesList}>
                {amenities.map((amenity, index) => (
                  <AmenityItem key={`amenity-${index}`} text={amenity} />
                ))}
              </div>
            </div>
            <div className={styles.restrictionsColumn}>
              <div className={styles.restrictionsList}>
                {restrictions.map((restriction, index) => (
                  <RestrictionItem
                    key={`restriction-${index}`}
                    text={restriction}
                  />
                ))}
                <div className={styles.priceSection}>
                  <div className={styles.priceWrapper}>
                    <span> ₹1,999</span>
                  </div>
                  <div className={styles.taxInfo}>
                    + 489 Taxes & Fees per night
                  </div>
                  <button
                    onClick={() => navigate(`/hotel-listing/rooms/${"nd"}`)} // Use useNavigate inline
                    className={styles.selectButton}
                    tabIndex="0"
                  >
                    Select Room
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
