import React from "react";
import styles from "./FlightBookingReview.module.css";
import { FlightSummary } from "../PassengerDetails/components/FlightSummary";
import FlightReviewCompo from "./FlightReviewCompo";

const FlightBookingReview = () => {
  return (
    <>
    <div className={styles.container}>
      <main className={styles.mainContent}>
        <div className={styles.contentGrid}>
          <div className={styles.leftColumn}>
            <h1>Review Details</h1>
            <FlightReviewCompo />
          </div>

          <div className={styles.rightColumn}>
            <FlightSummary />
          </div>
        </div>
      </main>
    </div>
    </>
  );
};

export default FlightBookingReview;
