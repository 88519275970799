import React, { useState } from "react";
import "./PriceSlider.css";

const PriceSlider = () => {
  const [minPrice, setMinPrice] = useState(2500);
  const [maxPrice, setMaxPrice] = useState(7500);
  const priceGap = 1000;

  const handleRangeChange = (e, type) => {
    const value = parseInt(e.target.value);

    if (type === "min" && value + priceGap <= maxPrice) {
      setMinPrice(value);
    }

    if (type === "max" && value - priceGap >= minPrice) {
      setMaxPrice(value);
    }
  };

  const handleInputChange = (e, type) => {
    const value = parseInt(e.target.value);

    if (type === "min" && value + priceGap <= maxPrice) {
      setMinPrice(value);
    }

    if (type === "max" && value - priceGap >= minPrice) {
      setMaxPrice(value);
    }
  };

  return (
    <div>
      <br />
      <p className="pranges"> Price Ranges</p>
      <div className="slider">
        <div
          className="progress"
          style={{
            left: `${(minPrice / 10000) * 100}%`,
            right: `${100 - (maxPrice / 10000) * 100}%`,
          }}
        />
      </div>

      <div className="range-input">
        <input
          type="range"
          className="range-min"
          min="0"
          max="10000"
          value={minPrice}
          step="100"
          onChange={(e) => handleRangeChange(e, "min")}
        />
        <input
          type="range"
          className="range-max"
          min="0"
          max="10000"
          value={maxPrice}
          step="100"
          onChange={(e) => handleRangeChange(e, "max")}
        />
      </div>
      <p className="priceshower">
        Rs{minPrice}-{maxPrice}
      </p>
    </div>
  );
};

export default PriceSlider;
