import React, { Fragment, useState } from "react";

const FlightPaxNAdults = ({
  onToggleDropdown,
  onAdultsNChildSelect,
  paxNChildsValues,
}) => {
  const [counts, setCounts] = useState(paxNChildsValues);
  const updateCount = (key, delta, e) => {
    e.stopPropagation();
    setCounts((prev) => ({
      ...prev,
      [key]: Math.max(key === "Adults" ? 1 : 0, prev[key] + delta),
    }));
  };
  const handleDone = (e) => {
    e.stopPropagation();
    onAdultsNChildSelect(counts);
    onToggleDropdown();
  };
  const options = [
    { label: "Adults", key: "Adults" },
    { label: "Children", key: "Children" },
    { label: "Infants", key: "Infants" },
  ];
  return (
    <>
      <div className="flight-grdiv">
        <div>
          <div>
            {options.map(({ label, key }) => (
              <Fragment key={key}>
                <div className="incdecwrap">
                  <span>{label}</span>
                  <div>
                    <button
                      className="decbtn"
                      onClick={(e) => updateCount(key, -1, e)}
                      disabled={
                        counts[key] <=
                        (key === "Adults" || key === "Rooms" ? 1 : 0)
                      }
                    >
                      -
                    </button>
                    {counts[key]}
                    <button
                      className="incbtn"
                      onClick={(e) => updateCount(key, 1, e)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <hr />
              </Fragment>
            ))}
            <hr />
            <div className="paxsubmitwrapper">
              <button onClick={handleDone}>Done</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlightPaxNAdults;
