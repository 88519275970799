import React from "react";
import styles from "./DateGuestInfo.module.css";
import { Calendar } from "lucide-react";

export const DateGuestInfo = ({ dates, guestCount }) => {
  return (
    <div className={styles.container}>
      <div className={styles.dateContainer}>
        <Calendar className={styles.calendarIcon} />
        <div>{dates}</div>
      </div>
      <div className={styles.dateContainer}>{guestCount} guests</div>
    </div>
  );
};
