import React from "react";
import styles from "../styles/Checkbox.module.css";

export const Checkbox = ({ label }) => {
  return (
    <div className={styles.checkboxContainer}>
      <input
        type="checkbox"
        id={label.toLowerCase().replace(/\s/g, "-")}
        className={styles.checkbox}
      />
      <label
        htmlFor={label.toLowerCase().replace(/\s/g, "-")}
        className={styles.label}
      >
        {label}
      </label>
    </div>
  );
};
