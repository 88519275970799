import React from "react";
import styles from "./RoomFacilities.module.css";

export const FacilityItem = ({ icon, text }) => {
  return (
    <div className={styles.facilityItem}>
      <img loading="lazy" src={icon} alt="" className={styles.facilityIcon} />
      <div>{text}</div>
    </div>
  );
};
