import React from "react";
import { FilterCheckbox } from "./FilterCheckbox";
import { StarRating } from "./StarRating";
import styles from "./Filters.module.css";
import NavbarNew from "../../../../Components/Navbar/NavbarNew";
import { HotelCard } from "../../../hotel_components/card/hotelCard/HotelCard";
import useWindowSize from "../../../../Hooks/useWindowSize";
import HotelListingMobile from "../../../[Mobile]/hotel-listing/HotelListingMobile";
const priceRanges = [
  { label: "Less than Rs.1,000" },
  { label: "Rs.1,000 - Rs.2,000" },
  { label: "Rs.2,000- Rs.25,00" },
  { label: "Rs.3,000- Rs.4,000" },
  { label: "Greater than Rs. 5,000" },
];

const propertyTypes = [
  { label: "Resort" },
  { label: "Guesthouse" },
  { label: "Hotels" },
  { label: "Villa" },
  { label: "Cottage" },
  { label: "Farm House" },
  { label: "Apartment" },
];

const stayDurations = [
  { label: "1 - 2 days" },
  { label: "2- 3 days" },
  { label: "4- 5days" },
  { label: "More than 5 days" },
];

const HotelListing = () => {
  const size = useWindowSize();
  return (
    <>
      <NavbarNew />
      {size.width > 469 ? (
        <div className={styles.flwrapper}>
          <div className={styles.flwrapperc1}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterContainer}>
                <h2 className={styles.filterTitle}>Price For 1 Room/Night</h2>
                {priceRanges.map((range, index) => (
                  <FilterCheckbox key={index} label={range.label} />
                ))}

                <h2 className={styles.filterTitle}>Star Rating</h2>

                {Array.from({ length: 3 }, (_, id) => (
                  <div key={id} className={styles.starRatingContainer}>
                    <input type="checkbox" className={styles.checkbox} />
                    <StarRating stars={3 - id} />
                  </div>
                ))}
                <h2 className={styles.filterTitle}>Property Type</h2>
                {propertyTypes.map((type, index) => (
                  <FilterCheckbox key={index} label={type.label} />
                ))}

                <h2 className={styles.filterTitle}>No. of Days</h2>
                {stayDurations.map((duration, index) => (
                  <FilterCheckbox key={index} label={duration.label} />
                ))}
              </div>
            </div>
          </div>
          <div className={styles.flwrapperc2}>
            {Array.from({ length: 9 }, (_, id) => (
              <HotelCard key={id} />
            ))}
          </div>
        </div>
      ) : (
        <HotelListingMobile />
      )}
    </>
  );
};

export default HotelListing;
